<template>
  <div class="navbar navbar-expand-md bg-light shadow py-3" v-if="!$route.meta.hideNavbar">
    <div class="container">
      <router-link class="text-dark font-weight-bold font-size-xl" to="/"> Wave Planner </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <div class="navbar-nav ml-auto">
          <nav-alert />
          <b-nav-item>
            <support-button></support-button>
          </b-nav-item>
          <router-link class="nav-item nav-link" to="/">Home</router-link>
          <b-nav-item-dropdown right v-if="user" menu-class="rounded-lg border-0 shadow">
            <template slot="button-content">
              {{ user && user.email }}
              <b-badge class="ml-1" variant="secondary">{{ user.role }}</b-badge>
            </template>
            <b-dropdown-item @click="logout">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { STATE_SUCCESS } from '../store/baseRequestStore';
import NavAlert from './NavAlert.vue';
import SupportButton from './SupportButton.vue';

export default {
  name: 'NavBar',
  components: {
    NavAlert,
    SupportButton,
  },

  computed: {
    ...mapState('auth/logout', ['requestState', 'error']),
    ...mapState('auth', ['isAuth', 'user']),
  },

  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout/sendRequest');

      if (this.requestState === STATE_SUCCESS) {
        this.$bvToast.toast('Logged out successfully', {
          title: 'Success',
          toaster: 'b-toaster-bottom-right',
          variant: 'primary',
          autoHideDelay: 5000,
          solid: true,
          appendToast: true,
        });
        this.$router.push('/login');
      }
    },
  },
};
</script>

<style lang="scss">
</style>
