<template>
  <b-button to="/support" variant="primary" size="sm">
    Support
    <b-badge
      v-if="showErrorsBadge"
      to="/support"
      variant="danger"
      :title="badgeErrorText"
      v-b-tooltip.hover.v-danger
    >
      {{ errorsCount }}
    </b-badge>
  </b-button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SupportButton',
  data() {
    return {
      badgeErrorText: '# of errors ready for report',
    };
  },
  computed: {
    ...mapGetters('errors', { errorsCount: 'errorsCount' }),
    showErrorsBadge() {
      return !!(this.errorsCount)
    },
  },
};
</script>

<style lang="scss">
</style>
