<template>
  <b-container>
    <b-row v-for="(condition, conditionIdx) in rule.conditions" :key="conditionIdx">
      <b-col cols="2">
        <p class="mt-2">{{ conditionIdx === 0 ? 'if' : 'and' }}</p>
      </b-col>
      <b-col>
        <b-form-select
          :disabled="isLocked"
          v-model="condition.key"
          :options="keys"
          @change="$forceUpdate()"
        ></b-form-select>
      </b-col>
      <b-col>
        <b-form-select
          v-if="condition.key && keyObjectFromValue(condition.key).type === 'string'"
          :disabled="isLocked || !condition.key || !keyObjectFromValue(condition.key).type"
          v-model="condition.operation"
          :options="stringOptions"
        ></b-form-select>
        <b-form-select
          v-else-if="condition.key && keyObjectFromValue(condition.key).type === 'number'"
          :disabled="isLocked || !condition.key || !keyObjectFromValue(condition.key).type"
          v-model="condition.operation"
          :options="numericOptions"
        ></b-form-select>
        <b-form-select
          v-else-if="condition.key && keyObjectFromValue(condition.key).type === 'boolean'"
          :disabled="isLocked || !condition.key || !keyObjectFromValue(condition.key).type"
          v-model="condition.operation"
          :options="booleanOptions"
        ></b-form-select>
        <b-form-select v-else :disabled="true"></b-form-select>
      </b-col>
      <b-col>
        <b-form-select
          v-if="condition.key && keyObjectFromValue(condition.key).type === 'boolean'"
          :type="condition.key && keyObjectFromValue(condition.key).type === 'boolean'"
          :disabled="isLocked"
          :options="formSelectBooleanOptions"
          v-model="condition.value"
        >
        </b-form-select>
        <b-form-input
          v-else
          :type="condition.key && keyObjectFromValue(condition.key).type === 'number' ? 'number' : 'text'"
          :disabled="isLocked"
          v-model="condition.value"
        ></b-form-input>
      </b-col>
      <b-col cols="1">
        <b-btn
          v-if="conditionIdx !== 0"
          class="mt-1"
          variant="outline-danger"
          size="sm"
          @click="rule.conditions.splice(conditionIdx, 1)"
        >
          <b-icon icon="x" />
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col offset="11">
        <b-btn variant="outline-primary" size="sm" @click="rule.conditions.push({})">
          <b-icon icon="plus" />
        </b-btn>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col cols="2">
        <p class="mt-2">then</p>
      </b-col>
      <b-col>
        <b-form-select :disabled="isLocked || !rule" v-model="rule.result" :options="groups"></b-form-select>
      </b-col>
    </b-row>
    <hr />
    <b-row class="mt-2">
      <b-col>
        <b-form-textarea placeholder="Notes" v-model="rule.notes" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <p class="text-center mb-0">
          <span v-for="(result, idx) in results" :key="idx">
            <a
              v-if="result.merchants.length > 0"
              @click="onMerchantSlideOpen(`${result.merchants.length} ${result.text}`, result.merchants)"
              >{{ result.merchants.length }} {{ result.text }}</a
            >
            <span v-else>{{ result.merchants.length }} {{ result.text }}</span>

            <span v-if="idx < results.length - 1">, </span>
            <span v-else>.</span>
          </span>
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import _ from 'lodash';
import {
  EQUALS_OP,
  NOT_EQUALS_OP,
  LESS_OP,
  GREATER_OP,
  CONTAINS_OP,
  IS_ONE_OP,
  NOT_CONTAINS_OP,
  IS_NOT_ONE_OP,
} from '../services/GroupRules';
import merchantSlideHandler from '../services/MerchantSlideHandler';

export default {
  props: {
    rule: Object,
    measurement: Object,
    keys: Array,
    groups: Array,
    items: Array,
  },
  watch: {
    rule: {
      handler() {
        if (!this.rule.conditions) {
          this.rule.conditions = [];
        }

        if (this.rule.conditions.length === 0) {
          this.rule.conditions.push({});
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isLocked() {
      return this.rule && (this.rule.locked || !this.rule.enabled);
    },
    results() {
      if (!this.measurement || _.isEmpty(this.measurement)) {
        return [];
      }
      return Object.keys(this.measurement).map((groupValue) => {
        const foundGroup = this.groupFromValue(groupValue);
        if (!foundGroup) {
          return '';
        }

        return {
          text: foundGroup.postDisplay,
          merchants: this.measurement[groupValue],
        };
      });
    },
  },
  methods: {
    areConditionsValid(conditions) {
      if (!conditions) {
        return false;
      }
      return _.every(conditions, (condition) => condition.key && condition.operation && condition.value);
    },
    onMerchantSlideOpen(title, merchantIds) {
      merchantSlideHandler.openSlide(
        title,
        this.items.filter((item) => merchantIds.indexOf(item.merchantId) > -1)
      );
    },
    keyObjectFromValue(value) {
      return _.find(this.keys, { value }) || {};
    },
    groupFromValue(key) {
      return _.find(this.groups, { value: key });
    },
  },
  data() {
    return {
      stringOptions: [EQUALS_OP, NOT_EQUALS_OP, CONTAINS_OP, NOT_CONTAINS_OP, IS_ONE_OP, IS_NOT_ONE_OP],
      numericOptions: [LESS_OP, EQUALS_OP, GREATER_OP],
      booleanOptions: [EQUALS_OP, NOT_EQUALS_OP],
      formSelectBooleanOptions: [{ value: 'true', text: 'true'}, { value: 'false', text: 'false' }],
    };
  },
};
</script>
