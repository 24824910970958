<template>
  <div class="h-100 minh-100 flex-fill d-flex align-items-center justify-content-center">
    <div class="d-flex flex-column p-5 rounded-lg bg-white shadow-lg align-items-center">
      <h1 class="mb-3 font-weight-bold">Wave Planner</h1>
      <span class="mb-4">You are not authenticated</span>
      <a :href="authLink" class="btn btn-primary rounded-pill px-3 py-2 align-self-stretch">Log in with Google</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {},
  computed: {
    authLink() {
      return `${process.env.VUE_APP_API_URL}/auth/google`;
    },
  },
};
</script>
