<template lang="html">
  <div class="container mt-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <b-card class="p-3">
          <h1 class="text-center mb-4 display-4">Wave Planner Support</h1>
          <img class="d-block mx-auto dolphin-support-image mb-4" :src="dolphinSupportImage" alt="Wave Planner Support">
          <b-form>
            <b-form-group id="user-email-group" :label="userEmailLabel" label-for="user-email">
              <b-form-input id="user-email" class="form-control placeholder-grey" type="email" v-model="userEmail" readOnly />
            </b-form-group>
            <b-form-group id="user-details-group" :label="userCommentsLabel" label-for="user-details">
              <b-form-textarea id="user-details" rows="3" :placeholder="userCommentsPlaceHolderText" class="user-placeholder-text" v-model="userComment" />
            </b-form-group>
              <b-button type="submit" variant="primary" @click="handleSubmit" :disabled="handleDisableSubmit()">Submit</b-button>
            </b-form>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import axios from 'axios';
import dolphinSupportImage from '@/assets/dolphin_support.png';

export default {
  name: 'Support',
  data() {
    return {
      userEmailLabel: 'Wave Planner support team will reply to this email if needed:',
      userCommentsLabel: 'Please tell us your support request or if you experienced a generic error, please click submit.',
      userCommentsPlaceHolderText: 'Please provide some details about the error or support request...',
      userComment: '',
      userEmail: 'example@e-hps.com',
      delaySubmit: false,
      submitTimerDelay: 7000,
      dolphinSupportImage,
      disableSubmit: true,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({
      currentErrors: 'errors/currentErrors',
    }),
  },
  mounted() {
    this.userEmail = this.user.email;
  },
  methods: {
    ...mapActions('errors', ['displayNavAlertError', 'displayNavAlertSuccess']),
    async handleSubmit() {
      this.delaySubmit = true;
      const alertMessage = {
        title: 'Support Error',
        body: 'Unable to submit support request at this time.\n\nPlease try again a little later.',
        footer: 'You can email support directly at\nwaveplanner@heartland.us',
      }

      try {
        await axios.post(`${process.env.VUE_APP_API_URL}/support/email-support`, {
          userComment: this.userComment,
          currentErrors: this.currentErrors,
        }, {
          withCredentials: true,
        });

        this.userComment = '';
        this.displayNavAlertSuccess();
        this.submitSuccess = true;
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
      setTimeout(() => {
        this.delaySubmit = false;
      }, this.submitTimerDelay);
    },
    handleDisableSubmit() {
      return !(!!Object.keys(this.currentErrors).length || !!this.userComment.length) || this.delaySubmit;
    },
  },
};
</script>

<style lang="scss" scoped>
.dolphin-support-image {
  max-width: 50%;
  height: auto;
}

.dolphin {
  font-size: 50px;
  line-height: 1;
  color: #007bff;
}
.dolphin:before {
  content: "(\\/)";
}

.user-placeholder-text {
  &::placeholder {
    color: #7e7e7e;
  }
}
#user-email {
  background-color: #f8f9fa;
  &:focus {
    background-color: #fff;
  }
}
</style>
