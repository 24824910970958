export const STATE_PRE = 'STATE_PRE';
export const STATE_IN_PROGRESS = 'STATE_IN_PROGRESS';
export const STATE_SUCCESS = 'STATE_SUCCESS';
export const STATE_FAIL = 'STATE_FAIL';

export default {
  namespaced: true,

  state: {
    requestState: STATE_PRE,
    data: null,
    error: null,
  },

  mutations: {
    setRequestState(state, requestState) {
      state.requestState = requestState;
    },

    setData(state, data) {
      state.data = data;
    },

    setError(state, error) {
      state.error = error;
    },

    reset(state) {
      state.requestState = STATE_PRE;
      state.data = null;
      state.error = null;
    },
  },

  getters: {
    successful: (state) => state.requestState === STATE_SUCCESS,
    failed: (state) => state.requestState === STATE_FAIL,
    inProgress: (state) => state.requestState === STATE_IN_PROGRESS,
  },

  actions: {
    sendRequest({ commit }) {
      const notOverwrittenError = new Error('The makeRequest function must be overwritten');

      commit('setRequestState', STATE_FAIL);
      commit('setError', notOverwrittenError);

      throw notOverwrittenError;
    },
  },
};
