export function isClientError(data) {
  if (!data) {
    return false;
  }
  const errorCodeRegex = /4[0-9]{2}/;
  return errorCodeRegex.test(data.toString());
}

export function isServerError(data) {
  if (!data) {
    return false;
  }
  const errorCodeRegex = /5[0-9]{2}/;
  return errorCodeRegex.test(data.toString());
}

export function formatErrorKeys(err, alertData) {
  const missingErrMessage = 'Error message not provided.';
  const errorResponseKeys = ['errorCode', 'message', 'query'];

  return {
    ...(alertData && alertData.title ? { alertTitle: alertData.title } : {}),
    ...(alertData && alertData.body ?  { alertBody: alertData.body } : {}),
    ...(err && err.message ? { clientMessage: err.message } : missingErrMessage),
    ...errorResponseKeys.reduce((obj, key) => err?.response?.data[key] ? { ...obj, [key]: err.response.data[key] } : obj, {})
  };
}
