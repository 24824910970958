<template>
  <div :style="cellStyle" class="heatmap-cell p-1">
    <b-col>
      <div class="text-center">
        <!-- ({{ value.range }}) -->
        <!-- <br /> -->
        {{ value.count }}
      </div>
    </b-col>
    <b-tooltip :target="cellID" triggers="hover" variant="light" placement="top">
      {{ calculateTooltipBPS(value) }}
    </b-tooltip>
  </div>
</template>
<script>
export default {
  name: 'HeatMapCell',
  components: {},
  computed: {
    cellStyle() {
      if (!this.highestValue || !this.color) {
        return '#000000';
      }
      const alpha = Math.round(255 * (this.value.count / this.highestValue));
      return {
        color: alpha < 160 ? 'black' : 'white',
        backgroundColor: `${this.color}${alpha.toString(16).padStart(2, '0')}`,
      };
    },
  },
  props: {
    cellID: String,
    value: Object,
    highestValue: Number,
    color: String,
  },
  data() {
    return {};
  },
  methods: {
    calculateTooltipBPS(value) {
      if (value && value.merchants && value.merchants.length === 0) {
        return 'No Merchants';
      }
      if (value && value.minRange && value.maxRange) {
        return `${value.minRange} - ${value.maxRange} BPS`;
      }
      return 'Data Missing';
    },
  },
};
</script>

<style scoped>
.heatmap-cell {
  width: 100%;
}
</style>
