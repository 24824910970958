<template>
  <b-nav-item class="navbar-alert">
    <b-alert
      :show="alertMessage.dismissCountDown"
      :variant="alertMessage.variant"
      fade
      dismissible
      @dismissed="hideAlert"
    >
      <p class="alert-title">{{ alertMessage.title }}</p>
      <p class="alert-body">{{ alertMessage.body }}</p>
      <span v-if="alertMessage.footer">
        <hr class="alert-hr">
        <p class="alert-footer">{{ alertMessage.footer }}</p>
        <b-button to="/support" variant="primary" size="sm">
          Support
        </b-button>
      </span>
    </b-alert>
  </b-nav-item>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'NavAlert',
  computed: {
    ...mapGetters('errors', {
      alertMessage: 'alertMessage',
    }),
  },
  methods: {
    ...mapActions('errors', ['hideNavAlert']),
    hideAlert() {
      this.hideNavAlert();
    },
  },
};
</script>

<style lang="scss">
.navbar-alert {
  position: absolute;
  top: 60px;
  right: 15px;
  z-index: 1000;
  margin-top: 5px;

  @media (max-width: 991px) {
    top: 75px;
  }
  .alert {
    padding: 0.75rem 0.75rem;
  }

  .alert-title {
    font-weight: bold;
    font-size: 0.9rem;
  }

  .alert-body {
    font-size: 0.8em;
    white-space: pre-line;
  }

  .alert-footer {
    font-size: 0.8em;
    color: #888;
    margin-bottom: 0.5em;
    margin-top: 0.25em;
    white-space: pre-line;
  }

  .alert-hr {
    margin-bottom: 0.5em;
  }
}
</style>