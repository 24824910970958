<template>
  <div class="pl-3">
    <div
      @click="expanded = !expanded"
      class="d-flex p-3 justify-content-between align-items-center wave-iteration"
      :class="{ 'cursor-pointer': waveIteration.subWaveIterations.length > 0 }"
    >
      <div class="d-flex flex-column">
        <router-link class="text-decoration-none" :to="`/waveIterations/${waveIteration.id}`">
          <div class="font-weight-bold font-size-lg text-dark">
            {{ waveIteration.name }}
          </div>
        </router-link>
        <div class="text-muted">{{ waveIteration.merchantCount }} Merchants</div>
      </div>
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column align-items-end mr-1">
          <h3 class="mb-0">
            <b-badge class="rounded-lg px-3" :variant="statusToVariant(waveIteration.status)">{{
              waveIteration.status
            }}</b-badge>
          </h3>
          <div v-if="waveIteration.status !== 'legacy' && waveIteration.updatedAt" class="text-muted">
            Modified on {{ formatDate(parseDate(waveIteration.updatedAt), 'M/d/yy') }} ({{
              formatDistanceToNow(parseDate(waveIteration.updatedAt), { addSuffix: true })
            }})
          </div>
        </div>
        <div class="mr-3 text-muted">
          {{
            waveIteration.subWaveIterations.length ? `${waveIteration.subWaveIterations.length} Wave Iterations` : ''
          }}
        </div>
        <div v-if="waveIteration.subWaveIterations.length > 0" class="text-muted">
          <b-icon-chevron-up v-if="expanded"></b-icon-chevron-up>
          <b-icon-chevron-down v-else></b-icon-chevron-down>
        </div>
      </div>
    </div>
    <div>
      <b-collapse :visible="expanded">
        <hr class="border-top my-0 mx-4" />
        <div
          @click="onCreateWaveIteration({ waveId: waveIteration.waveId, sourceWaveIterationId: waveIteration.id })"
          class="
            cursor-pointer
            text-center
            px-4
            pt-2
            pb-0
            mt-2
            font-size-xl
            d-flex
            align-items-left
            justify-content-left
          "
        >
          <b-icon icon="plus" font-scale="2" variant="primary"></b-icon>
          <p class="mt-1">Wave Iteration</p>
          <p></p>
        </div>

        <WaveIterationListViewItem
          @onCreateWaveIteration="onCreateWaveIteration"
          v-for="subWaveIteration in waveIteration.subWaveIterations"
          :key="subWaveIteration.id"
          :wave-iteration="subWaveIteration"
        />
      </b-collapse>
    </div>
  </div>
</template>
<script>
import { format, parseJSON, formatDistanceToNow } from 'date-fns';
import { statusToVariant } from '../utils';

export default {
  name: 'WaveIterationListViewItem',
  props: {
    waveIteration: { type: Object, required: true },
  },
  emits: ['onCreateWaveIteration'],
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    onCreateWaveIteration(newWaveIterationParams) {
      this.$emit('onCreateWaveIteration', newWaveIterationParams);
    },
    statusToVariant,
    parseDate: parseJSON,
    formatDate: format,
    formatDistanceToNow,
  },
};
</script>
