class MerchantSlideHandler {
  setHandler(handler) {
    this.handler = handler;
  }

  openSlide(title, merchants) {
    if (this.handler) {
      this.handler(title, merchants);
    }
  }
}

const merchantSlideHandler = new MerchantSlideHandler();

export default merchantSlideHandler;