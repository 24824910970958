<template>
  <div>
    <b-row>
      <b-col offset="6" cols="2">
        <b-form-input
          v-if="currentMode === portfolioModeOption"
          @change="setPortfolioHeatmapDataDebounce"
          @input="setPortfolioHeatmapDataDebounce"
          placeholder="Max BPS"
          id="portfolio-max-bps"
          class="rounded-pill"
          v-model="portfolioMaxBps"
          number
          type="number"
          required
        ></b-form-input>
      </b-col>
      <b-col cols="4">
        <b-form-select
          @change="modeChanged"
          class="rounded-pill"
          v-model="currentMode"
          :options="modes"
        ></b-form-select>
      </b-col>
    </b-row>
    <HeatMap
      @cellClick="onBeforeHeatCellClick"
      :data="currentHeatmapData().before"
      tableLabel="Before"
      :columnHeaders="DECILE_GROUP_LABELS"
      :rowHeaders="VOLUME_RANGES"
      :customNameForID="decileBeforeHeatMapText"
    />
    <HeatMap
      @cellClick="onAfterHeatCellClick"
      :data="currentHeatmapData().after"
      tableLabel="After"
      :columnHeaders="DECILE_GROUP_LABELS"
      class="mt-4"
      :rowHeaders="VOLUME_RANGES"
      :customNameForID="decileAfterHeatMapText"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';
import _ from 'lodash';
import { DECILE_GROUP_LABELS, VOLUME_RANGES, getMerchantDecile } from '@/utils';
import HeatMap from '@/components/HeatMap.vue';
import merchantSlideHandler from '../services/MerchantSlideHandler';

const industryModeOption = 'Industries';
const portfolioModeOption = 'Portfolio';

export default {
  name: 'HeatMapGroup',
  components: {
    HeatMap,
  },
  props: {
    industryDecileGroupsBefore: {
      type: Array,
      required: true,
    },
    industryDecileGroupsAfter: {
      type: Array,
      required: true,
    },
    merchants: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      VOLUME_RANGES,
      DECILE_GROUP_LABELS,
      currentMode: industryModeOption,
      modes: [industryModeOption, portfolioModeOption],
      decileBeforeHeatMapText: 'heat-before',
      decileAfterHeatMapText: 'heat-after',
      portfolioHeatmapData: null,
      industryModeOption,
      portfolioModeOption,
      portfolioMaxBps: 5000,
      setPortfolioHeatmapDataDebounced: null,
    };
  },
  mounted() {
    this.setPortfolioHeatmapDataDebounce = _.debounce(() => {
      this.setPortfolioHeatmapData();
    }, 1000);

    this.setPortfolioHeatmapData();
  },
  methods: {
    ...mapActions('errors', ['displayNavAlertError']),
    onBeforeHeatCellClick({ row, column }) {
      const currentData = this.currentHeatmapData();
      if (currentData.before[row][column].merchants && currentData.before[row][column].merchants.length > 0) {
        merchantSlideHandler.openSlide(
          `BEFORE - (${VOLUME_RANGES[row]}) - ${DECILE_GROUP_LABELS[column]}`,
          currentData.before[row][column].merchants
        );
      }
    },
    onAfterHeatCellClick({ row, column }) {
      const currentData = this.currentHeatmapData();
      if (currentData.after[row][column].merchants && currentData.after[row][column].merchants.length > 0) {
        merchantSlideHandler.openSlide(
          `AFTER - (${VOLUME_RANGES[row]}) - ${DECILE_GROUP_LABELS[column]}`,
          currentData.after[row][column].merchants
        );
      }
    },
    removeMerchantCounts(counts, merchantGroups) {
      const countsCopy = JSON.parse(JSON.stringify(counts));
      merchantGroups.forEach(merchantGroup => {
        const merchants = merchantGroup.includedMerchants && merchantGroup.includedMerchants.length > 0 ? merchantGroup.includedMerchants : [merchantGroup];

        merchants.forEach((merchant) => {
          if (!merchant.volumeRange || merchant.volumeRange < 0) {
            return;
          }

          const volumeRange = countsCopy[VOLUME_RANGES.indexOf(merchant.volumeRange)];

          const decile = getMerchantDecile(
            merchant.originalBps,
            volumeRange[0].minRange,
            volumeRange[0].maxRange - volumeRange[0].minRange,
            false
          );

          if (decile >= 0 && decile < 10) {
            volumeRange[decile].count -= 1;
          }
        });
      });
      return countsCopy;
    },
    // eslint-disable-next-line consistent-return
    async readPortfolioCounts() {
      const alertMessage = {
        title: 'Portfolio error',
        body: `Unable to read porfolio counts\nIteration: ${this.$route.params.waveIterationId}`,
      };
      try {
        const {
          data: { data },
        } = await axios.post(
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}/totals`,
          {
            maxBps: this.portfolioMaxBps,
          },
          {
            withCredentials: true,
          }
        );
  
        const dataWithoutCurrentMerchants = this.removeMerchantCounts(data, this.merchants);
  
        const formattedVolumeRanges = dataWithoutCurrentMerchants.map((volumeRange) =>
          volumeRange.map((decile) => ({ ...decile, merchants: [] }))
        );
        return formattedVolumeRanges;
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },
    async setPortfolioHeatmapData() {
      const alertMessage = {
        title: 'Portfolio error',
        body: `Unable to set portfolio\nIteration: ${this.$route.params.waveIterationId}`,
      };
      const portfolioCounts = await this.readPortfolioCounts();

      try {
        const heatmapDataBefore = JSON.parse(JSON.stringify(portfolioCounts));
        const heatmapDataAfter = JSON.parse(JSON.stringify(portfolioCounts));
        [
          [heatmapDataBefore, 'originalBps'],
          [heatmapDataAfter, 'proposedBps'],
        ].forEach((iteration) => {
          const [dataset, field] = iteration;

          this.merchants.forEach((merchant) => {
            const volumeRangeIndex = this.VOLUME_RANGES.indexOf(merchant.volumeRange);
            const minBPS = dataset[volumeRangeIndex][0].minRange;
            const maxBPS = dataset[volumeRangeIndex][dataset.length - 1].maxRange;
            const step = (maxBPS - minBPS) / 10;

            const index = getMerchantDecile(merchant[field], minBPS, step, false);

            if (index >= 0 && index < 9) {
              dataset[volumeRangeIndex][index].count += 1;
              dataset[volumeRangeIndex][index].merchants.push(merchant);
            }
          });
        });

        this.portfolioHeatmapData = {
          before: heatmapDataBefore,
          after: heatmapDataAfter,
        };
        this.$forceUpdate();
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },
    modeChanged() {
      if (this.currentMode === portfolioModeOption) {
        this.setPortfolioHeatmapData();
      }
    },
    currentHeatmapData() {
      if (this.currentMode === industryModeOption) {
        return {
          before: this.industryDecileGroupsBefore,
          after: this.industryDecileGroupsAfter,
        };
      }

      return {
        before: this.portfolioHeatmapData.before,
        after: this.portfolioHeatmapData.after,
      };
    },
  },
  watch: {
    merchants: {
      deep: true,
      handler() {
        this.setPortfolioHeatmapData();
      },
    },
  },
};
</script>

<style lang="scss"></style>
