<template>
  <div>
    <b-card class="mt-2" v-for="(rule, idx) in rules" :key="idx">
      <div class="controls">
        <b-btn class="mr-1" variant="primary" @click="moveRuleUp(idx)" :disabled="idx === 0">
          <b-icon icon="arrow-up" />
        </b-btn>
        <b-btn class="mr-1" variant="primary" @click="moveRuleDown(idx)" :disabled="idx === rules.length - 1">
          <b-icon icon="arrow-down" />
        </b-btn>
        <b-btn variant="danger" @click="deleteRuleConfirm(idx)">
          <b-icon icon="trash" />
        </b-btn>
      </div>
      <b-form-checkbox class="enabled-input" v-model="rule.enabled">
        {{ rule.enabled ? 'Enabled' : 'Disabled' }}
      </b-form-checkbox>
      <RulesetGroupItem
        class="mt-5"
        :measurement="getMeasurement(idx)"
        :rule="rule"
        :keys="availableKeys"
        :groups="groups"
        :items="items"
      />
    </b-card>
    <b-btn class="mt-2" variant="primary" block @click="rules.push({ enabled: true })">Add rule</b-btn>
  </div>
</template>

<script>
import _ from 'lodash';
import swal from 'sweetalert2';
import RulesetGroupItem from './RulesetGroupItem.vue';
import GroupRules from '../services/GroupRules';

export default {
  components: {
    RulesetGroupItem,
  },
  props: {
    rules: Array,
    groups: Array,
    items: Array,
    excludeKeys: Array,
    measurementKey: { type: String, required: true },
  },
  mounted() {
    this.groupRules = new GroupRules(this.measurementKey);
  },
  data() {
    return {
      groupRules: null,
      measurements: null,
    };
  },
  watch: {
    rules: {
      handler() {
        this.$forceUpdate();
        this.runRules();
      },
      deep: true,
    },
    groupRules: {
      handler() {
        this.runRules();
      },
      deep: true,
    },
    groups: {
      handler() {
        this.runRules();
      },
      deep: true,
    },
    items: {
      handler() {
        this.runRules();
      },
      deep: true,
    },
  },
  methods: {
    moveRuleUp(idx) {
      this.rules.splice(idx - 1, 0, this.rules.splice(idx, 1)[0]);
    },
    moveRuleDown(idx) {
      this.rules.splice(idx + 1, 0, this.rules.splice(idx, 1)[0]);
    },
    async deleteRuleConfirm(idx) {
      const { isConfirmed } = await swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to delete this rule completely?',
        confirmButtonText: 'Delete',
        showCancelButton: true,
        showCloseButton: true,
        cancelButtonText: 'Cancel',
      });

      if (isConfirmed) {
        this.rules.splice(idx, 1);
      }
    },
    runRules() {
      if (this.rulesReadyToRun) {
        this.measurements = this.groupRules.run(this.rules, this.groups, this.items);
      }
    },
    getMeasurement(index) {
      if (!this.measurements || index >= this.measurements.length) {
        return {};
      }

      return this.measurements[index];
    },
  },
  computed: {
    finalMeasurement() {
      if (!this.measurements || this.measurements.length === 0) {
        return {};
      }
      return this.measurements[this.measurements.length - 1];
    },
    rulesReadyToRun() {
      return !!(
        this.groupRules &&
        this.rules &&
        this.rules.length > 0 &&
        this.groups &&
        this.groups.length > 0 &&
        this.items &&
        this.items.length > 0
      );
    },
    availableKeys() {
      if (!this.items || this.items.length === 0) {
        return [];
      }
      const item = this.items[0];
      let keys = Object.keys(item);
      if (this.excludeKeys && this.excludeKeys.length > 0) {
        keys = keys.filter((key) => this.excludeKeys.indexOf(key) === -1);
      }

      return keys.map((key) => ({
        value: key,
        type: item[key] || typeof item[key] === 'boolean' ? typeof item[key] : 'string',
        text: _.startCase(key),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  position: absolute;
  top: 5px;
  right: 5px;
}
.enabled-input {
  position: absolute;
  top: 5px;
  left: 10px;
}
</style>
