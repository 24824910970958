<template>
  <div class="flex-fill container py-4">
    <h1>New Wave Iteration</h1>
    <div class="d-flex mt-4">
      <b-form-group class="flex-fill mr-2" id="input-group-2" label="Wave" label-for="input-2">
        <b-form-select
          class="rounded-pill px-3 py-2"
          v-model="createWaveIterationForm.waveId"
          :options="waveIdOptions"
          value-field="id"
          text-field="name"
        />
      </b-form-group>
      <b-form-group class="flex-fill mr-2" id="input-group-3" label="Source wave iteration" label-for="input-3">
        <b-form-select
          class="rounded-pill px-3 py-2"
          v-model="createWaveIterationForm.sourceWaveIterationId"
          :options="sourceWaveIterationIdOptions"
          value-field="id"
          text-field="name"
        />
      </b-form-group>
      <b-form-group
        class="flex-fill"
        id="input-group-1"
        label="New Wave Iteration name"
        label-for="input-1"
        description="Needs to be unique to this wave"
      >
        <b-form-input
          class="rounded-pill px-3 py-2"
          id="input-1"
          v-model="createWaveIterationForm.name"
          required
        ></b-form-input>
      </b-form-group>
    </div>
    <div>
      <b-spinner v-if="loading" label="Spinning"></b-spinner>
      <b-button v-else class="rounded-pill px-3 py-2" variant="primary" @click="submit">Create</b-button>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'CreateWaveIteration',
  components: {},
  props: {
    waveId: {
      type: String,
      default: null,
    },
    sourceWaveIterationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      createWaveIterationForm: {
        name: '',
        waveId: '',
        sourceWaveIterationId: '',
      },
      wavesMap: {},
      waves: [],
    };
  },
  created() {
    this.createWaveIterationForm.waveId = this.waveId;
    this.createWaveIterationForm.sourceWaveIterationId = this.sourceWaveIterationId;
    if (this.createWaveIterationForm.sourceWaveIterationId) {
      // Increment letter or something
      this.createWaveIterationForm.name = '';
    }
    this.listWaveIterations();
  },
  methods: {
    ...mapActions('errors', ['displayNavAlertError']),
    async submit() {
      this.loading = true;
      const alertMessage = {
        title: 'Create Wave Iteration Error',
        body: 'Unable to create Wave iteration.\n\nPlease try again later.',
      };

      try {
        const {
          data: {
            data: { id },
          },
        } = await axios.post(`${process.env.VUE_APP_API_URL}/waveIterations`, {
          name: this.createWaveIterationForm.name,
          waveId: this.createWaveIterationForm.waveId,
          sourceWaveIterationId: this.createWaveIterationForm.sourceWaveIterationId }, {
            withCredentials: true
        });

        this.loading = false;

        this.$router.push({
          path: `/waveIterations/${id}`,
        });
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },

    async listWaveIterations() {
      const alertMessage = {
        title: 'Wave Iterations Error',
        body: 'Unable to list Wave iterations.\n\nPlease try again later.',
      };

      try {
        const {
          data: { data: waves },
        } = await axios(`${process.env.VUE_APP_API_URL}/waves`, {
          withCredentials: true,
        });
        const {
          data: { data: waveIterations },
        } = await axios(`${process.env.VUE_APP_API_URL}/waveIterations`, {
          withCredentials: true,
        });

        const wavesMap = waveIterations.reduce((acc, waveIteration) => {
          if (!acc[waveIteration.waveId]) {
            acc[waveIteration.waveId] = [];
          }

          acc[waveIteration.waveId].unshift(waveIteration);

          return acc;
        }, {});

        this.waves = waves;
        this.wavesMap = wavesMap;
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },
  },

  computed: {
    waveIdOptions() {
      return this.waves;
    },
    sourceWaveIterationIdOptions() {
      return this.createWaveIterationForm.waveId && this.wavesMap[this.createWaveIterationForm.waveId]
        ? [{ name: 'None', id: null }, ...this.wavesMap[this.createWaveIterationForm.waveId]]
        : [];
    },
  },
};
</script>

<style lang="scss"></style>
