import axios from 'axios';
import baseRequestStore, { STATE_IN_PROGRESS, STATE_SUCCESS, STATE_FAIL } from '../baseRequestStore';

export default {
  ...baseRequestStore,
  actions: {
    async sendRequest({ commit, dispatch }) {
      commit('reset');
      commit('setRequestState', STATE_IN_PROGRESS);

      try {
        const response = await axios.delete(`${process.env.VUE_APP_API_URL}/auth`, { withCredentials: true });

        commit('setRequestState', STATE_SUCCESS);
        commit('setData', response.data);
        commit('auth/clearUser', null, { root: true });
      } catch (error) {
        const alertMessage = {
          title: 'Logout Error',
          body: 'Error occurred when trying to logout.',
        };
        dispatch('errors/displayNavAlertError', { error, alertMessage }, { root: true });
        commit('setRequestState', STATE_FAIL);
      }
    },
  },
};
