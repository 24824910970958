/* eslint-disable no-underscore-dangle */
import moment from 'moment';

class Cache {
  constructor(lifeSeconds, clearOldItemsSeconds) {
    this._cachedItems = {};
    this._lifeSeconds = lifeSeconds;
    this._clearOldItemsSeconds = clearOldItemsSeconds;

    this._clearOldItems = this._clearOldItems.bind(this);

    if (this._clearOldItemsSeconds) {
      setInterval(this._clearOldItems, this._clearOldItemsSeconds * 1000);
    }
  }

  async getItem(id, updateFunc) {
    if (
      !this._cachedItems[id] ||
      !this._cachedItems[id].expireDate ||
      moment().isAfter(this._cachedItems[id].expireDate)
    ) {
      const newVal = await updateFunc();
      this._cachedItems[id] = {
        expireDate: moment().add(this._lifeSeconds, 'seconds'),
        value: newVal,
      };
    }

    return this._cachedItems[id].value;
  }

  _clearOldItems() {
    const now = moment();
    Object.keys(this._cachedItems).forEach((cacheKey) => {
      if (now.isAfter(this._cachedItems[cacheKey].expireDate)) {
        delete this._cachedItems[cacheKey];
      }
    });
  }
}

export default Cache;
