<template>
  <div v-if="waveIteration" class="flex-fill container py-4">
    <div class="d-flex align-items-center mb-3">
      <h1 class="mr-2 mb-0">
        {{ waveIteration.name }}
      </h1>
      <div class="mr-2">
        <h4 class="mb-0">
          <b-badge class="mr-2 rounded-pill px-3 py-2" :variant="statusToVariant(waveIteration.status)">{{
            waveIteration.status
          }}</b-badge>
        </h4>
      </div>
      <b-button variant="secondary" @click="pullNewValues" class="mr-2 rounded-pill px-3 py-2"
        >Pull Updated Numbers</b-button
      >
      <b-button
        variant="danger"
        class="rounded-pill px-3 py-2"
        v-if="user && user.role && user.role === 'superadmin' && hasEditableStatus"
        @click="deleteWaveIteration"
        >Delete</b-button
      >
    </div>

    <b-tabs v-model="tab">
      <b-tab title="Heat Maps" active>
        <div v-if="!heatMapLoading" class="d-flex flex-row mt-2">
          <div class="sidebar-width mr-5">
            <b-form-select
              class="rounded-pill px-3 py-2"
              v-model="activeIndustry"
              :options="industries"
              @change="listWaveIterationMerchants"
            ></b-form-select>
            <div class="bg-dark text-white rounded-lg shadow-lg pb-3 mt-4">
              <div class="d-flex p-2">
                <div class="flex-fill" />
                <div class="font-size-xs text-center" style="flex: 0 0 56px; width: 100%">Min BPS</div>
                <div class="font-size-xs text-center" style="flex: 0 0 56px; width: 100%">Min Jump</div>
                <div class="font-size-xs text-center" style="flex: 0 0 56px; width: 100%">Ceiling</div>
              </div>
              <div class="px-2">
                <div class="d-flex align-items-center" v-for="volumeRange in VOLUME_RANGES" :key="volumeRange">
                  <div class="d-flex flex-fill p-1">
                    {{ volumeRange }}
                  </div>
                  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                  <input
                    v-if="waveIteration.status === 'draft'"
                    v-model="config.industries[activeIndustry].volumeRanges[volumeRange].minBPSJump"
                    type="number"
                    min="0"
                    step="1"
                    class="px-1 config-settings"
                  />
                  <div class="px-1 config-settings" v-else>
                    {{ config.industries[activeIndustry].volumeRanges[volumeRange].minBPSJump }}
                  </div>
                  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                  <input
                    v-if="waveIteration.status === 'draft'"
                    v-model="config.industries[activeIndustry].volumeRanges[volumeRange].minDecileJump"
                    type="number"
                    min="0"
                    max="10"
                    step="1"
                    class="px-1 config-settings"
                  />
                  <div class="px-1 config-settings" v-else>
                    {{ config.industries[activeIndustry].volumeRanges[volumeRange].minDecileJump }}
                  </div>
                  <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                  <input
                    v-if="waveIteration.status === 'draft'"
                    v-model="config.industries[activeIndustry].volumeRanges[volumeRange].decileCeiling"
                    type="number"
                    min="0"
                    max="10"
                    step="1"
                    class="px-1 config-settings"
                  />
                  <div class="px-1 config-settings" v-else>
                    {{ config.industries[activeIndustry].volumeRanges[volumeRange].decileCeiling }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-dark text-white rounded-lg shadow-lg mt-4 p-3">
              <div class="d-flex p-2">
                <div class="flex-fill">Annual Revenue Before</div>
                <div class="font-size text-center">
                  ${{ Math.floor(calculated.industryRevenueBefore).toLocaleString() }}
                </div>
              </div>
              <div class="d-flex p-2">
                <div class="flex-fill">BPS Delta</div>
                <div class="font-size text-center">
                  {{ numbertoFloat(calculated.bpsDelta) }}
                </div>
              </div>
              <div class="d-flex p-2">
                <div class="flex-fill">Flagged Merchant Count</div>
                <div class="font-size text-center">
                  {{ calculated.flaggedMerchantCount }}
                </div>
              </div>
              <div class="d-flex p-2">
                <div class="flex-fill">Change in Revenue</div>
                <div class="font-size text-center">${{ round(calculated.changeInRevenue).toLocaleString() }}</div>
              </div>
              <div class="d-flex p-2">
                <div class="flex-fill">Affected Merchants / Active Merchants</div>
                <div class="font-size text-center">
                  {{ calculated.affectedMerchants.affectedMerchants }} /
                  {{ calculated.affectedMerchants.allMerchants }}
                </div>
              </div>
              <div class="d-flex p-2">
                <div class="flex-fill">Annual Revenue After</div>
                <div class="font-size text-center">
                  <!-- eslint-disable-next-line vue/no-parsing-error -->
                  ({{ formatPercentageChange(calculated.revenueChangePercent) }}) ${{
                    Math.floor(calculated.industryRevenueAfter).toLocaleString()
                  }}
                </div>
              </div>
            </div>
            <b-button variant="primary" class="w-100 mt-2 rounded-pill px-3 py-2" @click="downloadActiveIndustryCSV"
              >Export as CSV</b-button
            >
            <div class="bg-dark text-white rounded-lg shadow-lg mt-4 p-3">
              <div v-if="globalMetricsLoading" class="flex-fill">
                <p>Loading...</p>
              </div>
              <div v-else>
                <div class="d-flex p-2">
                  <div class="flex-fill">Total Annual Revenue Before</div>
                  <div class="font-size text-center">
                    ${{ Math.floor(overallTotals().industryRevenueBefore).toLocaleString() }}
                  </div>
                </div>
                <div class="d-flex p-2">
                  <div class="flex-fill">BPS Delta</div>
                  <div class="font-size text-center">
                    {{ overallTotals().bpsDelta }}
                  </div>
                </div>
                <div class="d-flex p-2">
                  <div class="flex-fill">Flagged Merchant Count</div>
                  <div class="font-size text-center">
                    {{ overallTotals().flaggedMerchantCount }}
                  </div>
                </div>
                <div class="d-flex p-2">
                  <div class="flex-fill">Change in Revenue</div>
                  <div class="font-size text-center">
                    ${{ round(overallTotals().changeInRevenue).toLocaleString() }}
                  </div>
                </div>
                <div class="d-flex p-2">
                  <div class="flex-fill">Affected Merchants / Active Merchants</div>
                  <div class="font-size text-center">
                    {{ overallTotals().affectedMerchants.affectedMerchants }} /
                    {{ overallTotals().affectedMerchants.allMerchants }}
                  </div>
                </div>
                <div class="d-flex p-2">
                  <div class="flex-fill">Total Annual Revenue After</div>
                  <div class="font-size text-center">
                    <!-- eslint-disable-next-line vue/no-parsing-error -->
                    ({{ formatPercentageChange(overallTotals().revenueChangePercent) }}) ${{
                      Math.floor(overallTotals().industryRevenueAfter).toLocaleString()
                    }}
                  </div>
                </div>
              </div>
            </div>
            <b-button
              variant="primary"
              class="w-100 mt-2 rounded-pill px-3 py-2"
              @click="downloadAllCSV"
              :disabled="exportAllLoading"
            >
              <span v-if="!exportAllLoading">Export all as CSV</span>
              <span v-else>Loading</span>
            </b-button>
          </div>
          <div class="flex-fill">
            <HeatMapGroup
              :industryDecileGroupsBefore="calculated.decileGroupsBefore"
              :industryDecileGroupsAfter="calculated.decileGroupsAfter"
              :merchants="calculated.merchants"
            />
          </div>
        </div>
        <div v-else class="d-flex justify-content-center m-5">
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </b-tab>
      <b-tab>
        <template v-if="hasEditableStatus" slot="title">
          Filters
          <b-badge v-if="!allRulesValid" variant="danger" class="mt-0" pill>!</b-badge>
        </template>
        <RulesetGroup
          ref="excludeFilters"
          measurementKey="merchantId"
          :rules="config.excludeRules"
          :groups="excludeGroups"
          :items="waveMerchants"
          :excludeKeys="excludedMerchantKeys"
        />
      </b-tab>
      <b-tab title="Fee Preferences">
        <b-card class="mt-2">
          <b-row>
            <b-col>
              <label for="smp-monthly-fee">SMP Monthly Fee</label>
              <b-form-input
                id="smp-monthly-fee"
                class="rounded-pill"
                v-model="config.caps.smpMonthlyFee"
                number
                required
              ></b-form-input>
            </b-col>
            <b-col>
              <label for="smp-txn-fee">SMP Txn Fee</label>
              <b-form-input
                id="smp-txn-fee"
                class="rounded-pill"
                v-model="config.caps.smpTxnFee"
                number
                type="number"
                required
              ></b-form-input>
            </b-col>
            <b-col>
              <label for="asmp-txn-fee">ASMP Txn Fee Cap</label>
              <b-form-input
                id="asmp-txn-fee"
                class="rounded-pill"
                v-model="config.caps.asmpTxnFee"
                number
                type="number"
                required
              ></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-row class="mt-2">
          <b-col>
            <b-card>
              <label for="discount-cap">Discount Rate Cap</label>
              <b-row id="discount-cap">
                <b-col cols="2">
                  <b-form-checkbox v-model="config.caps.discountRateCapEnabled">Enabled</b-form-checkbox>
                </b-col>
                <b-col>
                  <b-form-input
                    :disabled="!config.caps.discountRateCapEnabled"
                    class="rounded-pill"
                    v-model="config.caps.discountRateCap"
                    number
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col>
            <b-card>
              <label for="txn-cap">Txn Fee Cap</label>
              <b-row id="txn-cap">
                <b-col cols="2">
                  <b-form-checkbox v-model="config.caps.txnFeeCapEnabled">Enabled</b-form-checkbox>
                </b-col>
                <b-col>
                  <b-form-input
                    :disabled="!config.caps.txnFeeCapEnabled"
                    class="rounded-pill"
                    v-model="config.caps.txnFeeCap"
                    number
                    type="number"
                    required
                  ></b-form-input>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-card class="mt-2">
          <table class="table table-striped">
            <tr>
              <th>Volume Range</th>
              <th>Mean Txn Fee</th>
              <th>Median Txn Fee</th>
              <th>Mean Discount Rate</th>
              <th>Median Discount Rate</th>
              <th></th>
            </tr>
            <tr v-for="(row, index) in Array.from(Array(10))" :key="index">
              <td>{{ VOLUME_RANGES[index] }}</td>
              <td>
                {{ formatCurrency(calculated.meanProposedTxnFees[index].value) }} ({{
                  calculated.meanProposedTxnFees[index].count
                }})
              </td>
              <td>
                {{ formatCurrency(calculated.medianProposedTxnFees[index].value) }} ({{
                  calculated.medianProposedTxnFees[index].count
                }})
              </td>
              <td>
                {{ formatMeanMedianProposedDiscountRate(calculated.meanProposedDiscountRates[index]) }}
              </td>
              <td>
                {{ formatMeanMedianProposedDiscountRate(calculated.medianProposedDiscountRates[index]) }}
              </td>
              <td>
                <b-form-select
                  class="rounded-pill px-3 py-2"
                  v-model="config.feeRules[VOLUME_RANGES[index]]"
                  :options="['Txn Fee', 'Discount Rate']"
                />
              </td>
            </tr>
          </table>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-sidebar width="80%" :title="merchantModalTitle" right shadow backdrop v-model="displayMerchantModal">
      <div class="px-3 py-2">
        <p>
          (A max of <b>{{ maxModalMerchants }}</b> merchants are shown)
        </p>
        <b-table striped hover :fields="merchantModalFields" :items="merchantModalMerchants">
          <template #head(volumeCount)> Txn Count </template>
          <template #cell(merchantId)="data">
            <a
              v-for="id in data.value.split(',')"
              :key="id"
              :style="{ display: 'block' }"
              :href="'https://atlas-reporting.heartland.us/#/merchants/' + id + '/profile'"
              target="_blank"
              rel="noopener noreferrer"
              >{{ id }}</a
            >
          </template>
          <template #cell(volumeAmount)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #head(revenue)> Annual Revenue </template>
          <template #cell(revenue)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #head(revenueAfter)> Annual Revenue After </template>
          <template #cell(revenueAfter)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(revenueLessPCI)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(originalTxnFee)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(proposedTxnFee)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(proposedTxnFeeIncreaseBy)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(originalMonthlyFee)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(proposedMonthlyFee)="data">
            {{ formatCurrency(data.value) }}
          </template>
          <template #cell(flag)="data">
            {{ formatFlagsArray(data.value) }}
          </template>
        </b-table>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import axios from 'axios';
import _, { defaultsDeep, debounce } from 'lodash';
import { mapState, mapActions } from 'vuex';
import RulesetGroup from '../components/RulesetGroup.vue';
import HeatMapGroup from '../components/HeatMapGroup.vue';
import {
  statusToVariant,
  DECILE_GROUP_LABELS,
  calculateDecileGroups,
  downloadCSVFromMerchants,
  VOLUME_RANGES,
  round,
  groupChains,
  flagPetroPayAccounts,
} from '../utils';
import Cache from '../services/Cache';
import displayConfirmAlert, { displayDeletionToast } from '../services/displayConfirm';
import merchantSlideHandler from '../services/MerchantSlideHandler';
import GroupRules from '../services/GroupRules';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// 30 minutes
const IndustryMerchantCache = new Cache(1800, 60);

export default {
  name: 'WaveIteration',
  components: {
    RulesetGroup,
    HeatMapGroup,
  },
  async mounted() {
    this.groupRules = new GroupRules('merchantId');
    merchantSlideHandler.setHandler(this.onMerchantSlideOpen);
    await Promise.all([this.listWaveIterationMerchants(), this.getWaveIteration(), this.listIndustries()]);
    this.debouncedSaveConfig = debounce(this.saveConfig, 2000);
    await this.computeGlobalMetrics();
  },
  data() {
    return {
      feeGroups: [
        { text: 'Prefer TXN Fee', value: 'txnFee', postDisplay: 'prefer TXN fee', default: true },
        { text: 'Prefer Discount Rate', value: 'discountRate', postDisplay: 'prefer discount rate', default: true },
      ],
      excludeGroups: [
        { text: 'Include', value: 'include', postDisplay: 'included', default: true },
        { text: 'Exclude', value: 'exclude', postDisplay: 'excluded' },
      ],
      excludedMerchantKeys: ['waveId', 'waveIterationId'],
      statuses: ['draft', 'submitted', 'approved', 'executed'],
      tab: null,
      groupRules: null,
      displayMerchantModal: false,
      merchantModalTitle: '',
      merchantModalMerchants: [],
      merchantModalFields: [
        'merchantId',
        'merchantName',
        'lineOfBusiness',
        'volumeAmount',
        'volumeCount',
        'revenue',
        'originalBps',
        'originalDiscountRate',
        'originalTxnFee',
        'originalMonthlyFee',
        'revenueAfter',
        'proposedBps',
        'proposedDiscountRate',
        'proposedDiscountRateIncreaseBy',
        'proposedTxnFee',
        'proposedTxnFeeIncreaseBy',
        'proposedMonthlyFee',
        'uwChainName',
        'uwChainOid',
        'prChainName',
        'prChainOid',
        'invoiceParentMSK',
        'isSMP',
        'isASMP',
        'isPetroPay',
        'flag',
      ],
      VOLUME_RANGES,
      waveMerchants: [],
      maxModalMerchants: 1000,
      waveIteration: null,
      industries: ['Restaurant'],
      activeIndustry: 'Restaurant',
      heatMapLoading: true,
      exportAllLoading: false,
      globalMetricsLoading: true,
      DECILE_GROUP_LABELS,
      config: {
        excludeRules: [],
        caps: {
          discountRateCapEnabled: true,
          discountRateCap: 0.02,
          txnFeeCapEnabled: true,
          txnFeeCap: 0.3,
          smpMonthlyFee: 72,
          smpTxnFee: 0.04,
          asmpTxnFee: 0.3,
        },
        feeRules: VOLUME_RANGES.reduce((acc, val) => {
          acc[val] = 'Txn Fee';
          return acc;
        }, {}),
        industries: {
          Restaurant: {
            volumeRanges: VOLUME_RANGES.reduce((acc, val) => {
              acc[val] = {
                minBPSJump: 0,
                minDecileJump: 0,
                decileCeiling: 0,
              };
              return acc;
            }, {}),
          },
        },
      },
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    allRulesValid() {
      if (!this.config.excludeRules || this.config.excludeRules.length === 0) {
        return true;
      }
      return _.every(this.config.excludeRules, (rule) => GroupRules.isValidRule(rule));
    },
    calculated() {
      let merchants = this.waveMerchants;
      if (
        this.$refs.excludeFilters &&
        this.$refs.excludeFilters.finalMeasurement &&
        this.$refs.excludeFilters.finalMeasurement.include
      ) {
        const merchantIds = this.$refs.excludeFilters.finalMeasurement.include;
        merchants = this.waveMerchants.filter((merchant) => merchantIds.indexOf(merchant.merchantId) > -1);
      }
      return calculateDecileGroups(
        merchants,
        this.activeIndustry,
        this.config.industries[this.activeIndustry],
        this.config.feeRules,
        this.config.caps.discountRateCapEnabled,
        this.config.caps.discountRateCap,
        this.config.caps.txnFeeCapEnabled,
        this.config.caps.txnFeeCap,
        this.config.caps.smpMonthlyFee,
        this.config.caps.smpTxnFee,
        this.config.caps.asmpTxnFee
      );
    },
    hasEditableStatus() {
      if (!this.waveIteration || this.waveIteration.status === 'executed' || this.waveIteration.status === 'approved') {
        return false;
      }
      return true;
    },
  },
  watch: {
    calculated: {
      handler() {
        if (this.isDeleting) {
          return;
        }
        if (this.config.industries && this.config.industries[this.activeIndustry]) {
          this.config.industries[this.activeIndustry].totals = {
            industryRevenueBefore: this.calculated.industryRevenueBefore,
            industryRevenueAfter: this.calculated.industryRevenueAfter,
            revenueChangePercent: this.calculated.revenueChangePercent,
            bpsDelta: this.calculated.bpsDelta,
            flaggedMerchantCount: this.calculated.flaggedMerchantCount,
            changeInRevenue: this.calculated.changeInRevenue,
            affectedMerchants: {
              affectedMerchants: this.calculated.affectedMerchants.affectedMerchants,
              allMerchants: this.calculated.affectedMerchants.allMerchants,
            },
          };
        }
      },
      deep: true,
    },
    config: {
      handler() {
        if (this.isDeleting) {
          return;
        }
        if (this.debouncedSaveConfig) {
          this.debouncedSaveConfig();
        }
      },
      deep: true,
    },
    async tab() {
      await this.computeGlobalMetrics();
    },
  },
  methods: {
    ...mapActions('errors', ['displayNavAlertError']),
    round,
    formatCurrency(amount) {
      if (amount === null || amount === undefined || Number.isNaN(amount)) {
        return '-';
      }

      return currencyFormatter.format(amount);
    },
    onMerchantSlideOpen(title, merchants) {
      this.merchantModalTitle = title;
      this.merchantModalMerchants = merchants.slice(0, this.maxModalMerchants);
      this.displayMerchantModal = true;
    },
    formatPercentageChange(value) {
      if (Number.isNaN(value)) {
        return '0%';
      }

      let finalVal = '';
      if (value > 0) {
        finalVal += '+';
      } else if (value < 0) {
        finalVal += '-';
      }

      finalVal += `${value.toLocaleString()}%`;
      return finalVal;
    },
    formatMeanMedianProposedDiscountRate(meanProposedDiscountRates) {
      const { value, count } = meanProposedDiscountRates;
      if (value === null || value === undefined || Number.isNaN(value)) {
        return `- (${count})`
      }
      return `${value} (${count})`
    },
    formatFlagsArray(flags) {
      if (!flags) {
        return '';
      }
      if (flags.length === 1) {
        return flags[0];
      }
      let flagsFromArrayToString = '';
      flags.forEach((flag, idx) => {
        if (idx === 0) {
          flagsFromArrayToString += `${flag}`
        } else {
          flagsFromArrayToString += `\n${flag}`
        }
      });
      return flagsFromArrayToString;
    },
    async computeGlobalMetrics() {
      const alertMessage = {
        title: 'Metrics Compilation Error.',
        body: `Unable to get metrics\nIteration: ${this.$route.params.waveIterationId} \nIndustry: ${this.activeIndustry}`,
      };

      try {
        this.globalMetricsLoading = true;
        const allMerchants = await this.getAllMerchants();
        const configCopy = { ...this.config };
        allMerchants.forEach((calculatedObject) => {
          if (configCopy.industries && configCopy.industries[calculatedObject.industry]) {
            configCopy.industries[calculatedObject.industry].totals = {
              industryRevenueBefore: calculatedObject.industryRevenueBefore,
              industryRevenueAfter: calculatedObject.industryRevenueAfter,
              revenueChangePercent: calculatedObject.revenueChangePercent,
              bpsDelta: calculatedObject.bpsDelta,
              flaggedMerchantCount: calculatedObject.flaggedMerchantCount,
              changeInRevenue: calculatedObject.changeInRevenue,
              affectedMerchants: {
                affectedMerchants: calculatedObject.affectedMerchants.affectedMerchants,
                allMerchants: calculatedObject.affectedMerchants.allMerchants,
              },
            };
          }
        });
        this.config = configCopy;
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      } finally {
        this.globalMetricsLoading = false;
      }
    },
    async downloadAllCSV() {
      const merchantDataError = new Error('Missing merchant data');
      const alertMessage = {
        title: 'Merchant Data Error',
        body: 'Unable to export to CSV at this time.\n\nPlease try again later.',
      };

      if (this.waveMerchants.length <= 0) {
        const errorString = 'Wave Merchants length <= 0';
        this.displayNavAlertError({ errorString, alertMessage });
        return;
      }

      try {
        this.exportAllLoading = true;
        const calculatedObjects = await this.getAllMerchants();
        downloadCSVFromMerchants(
          calculatedObjects.reduce((acc, volumeRange) => [...acc, ...volumeRange.merchants], [])
        );
      } catch (err) {
        if (this.waveMerchants.length <= 0) {
          this.displayNavAlertError({ merchantDataError, alertMessage });
        }
      } finally {
        this.exportAllLoading = false;
      }
    },
    async getAllMerchants() {
      let merchantRes = await Promise.all(this.industries.map((industry) => this.fetchMerchantData(industry)));
      merchantRes = merchantRes.map((volumeRange) => {
        let volumeRangeMerchants = volumeRange.volumeRanges;
        if (volumeRangeMerchants.length > 0) {
          const rulesRes = this.groupRules.run(this.config.excludeRules, this.excludeGroups, volumeRangeMerchants);
          if (rulesRes && rulesRes.length > 0) {
            volumeRangeMerchants = volumeRangeMerchants.filter(
              (merchant) => rulesRes[rulesRes.length - 1].include.indexOf(merchant.merchantId) > -1
            );
          }
        }

        return calculateDecileGroups(
          volumeRangeMerchants,
          volumeRange.industry,
          this.config.industries[volumeRange.industry],
          this.config.feeRules,
          this.config.caps.discountRateCapEnabled,
          this.config.caps.discountRateCap,
          this.config.caps.txnFeeCapEnabled,
          this.config.caps.txnFeeCap,
          this.config.caps.smpMonthlyFee,
          this.config.caps.smpTxnFee,
          this.config.caps.asmpTxnFee
        );
      });

      return merchantRes;
    },
    async fetchMerchantData(industry) {
      const result = await IndustryMerchantCache.getItem(
        `${industry}-${this.$route.params.waveIterationId}`,
        axios.bind(
          this,
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}/merchants?industry=${industry}`,
          { withCredentials: true }
        )
      );

      let merchants = flagPetroPayAccounts(result.data.data, 'invoiceParentMSK');
      merchants = groupChains(merchants, 'prChainOid');
      merchants = groupChains(merchants, 'uwChainOid');
      return {
        industry,
        volumeRanges: merchants,
      };
    },
    overallTotals() {
      const totals = Object.values(this.config.industries).reduce(
        (acc, volumeRange) => ({
          industryRevenueBefore:
            acc.industryRevenueBefore + ((volumeRange.totals && volumeRange.totals.industryRevenueBefore) || 0),
          industryRevenueAfter:
            acc.industryRevenueAfter + ((volumeRange.totals && volumeRange.totals.industryRevenueAfter) || 0),
          bpsDelta: acc.bpsDelta + ((volumeRange.totals && volumeRange.totals.bpsDelta) || 0),
          flaggedMerchantCount:
            acc.flaggedMerchantCount + ((volumeRange.totals && volumeRange.totals.flaggedMerchantCount) || 0),
          changeInRevenue: acc.changeInRevenue + ((volumeRange.totals && volumeRange.totals.changeInRevenue) || 0),
          affectedMerchants: {
            affectedMerchants:
              acc.affectedMerchants.affectedMerchants +
              ((volumeRange.totals && volumeRange.totals.affectedMerchants.affectedMerchants) || 0),
            allMerchants:
              acc.affectedMerchants.allMerchants +
              ((volumeRange.totals && volumeRange.totals.affectedMerchants.allMerchants) || 0),
          },
        }),
        {
          industryRevenueBefore: 0,
          industryRevenueAfter: 0,
          revenueChangePercent: 0,
          bpsDelta: 0,
          flaggedMerchantCount: 0,
          changeInRevenue: 0,
          affectedMerchants: {
            affectedMerchants: 0,
            allMerchants: 0,
          },
        }
      );

      totals.bpsDelta = Number.parseFloat(totals.bpsDelta).toFixed(2);
      totals.revenueChangePercent = Math.round(
        ((totals.industryRevenueAfter - totals.industryRevenueBefore) / totals.industryRevenueBefore) * 100
      );

      return totals;
    },
    async listWaveIterationMerchants() {
      this.heatMapLoading = true;
      const alertMessage = {
        title: 'Wave Merchant Error',
        body: `Iteration: ${this.$route.params.waveIterationId} \nIndustry: ${this.activeIndustry}`,
      };

      try {
        const waveMerchantsRes = await this.fetchMerchantData(this.activeIndustry);
        this.waveMerchants = waveMerchantsRes.volumeRanges;
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }

      this.heatMapLoading = false;
    },

    async getWaveIteration() {
      const alertMessage = {
        title: 'Wave Iterations Error',
        body: `Iteration: ${this.$route.params.waveIterationId}`,
      };

      try {
        const {
          data: { data },
        } = await axios(`${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}`, {
          withCredentials: true,
        });

        this.waveIteration = data;
        if (this.waveIteration.config) {
          this.config = JSON.parse(this.waveIteration.config);
          this.refreshConfig();
        }
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },

    async listIndustries() {
      const alertMessage = {
        title: 'Wave Industry Error',
        body: `Iteration: ${this.$route.params.waveIterationId}`,
      };

      try {
        const {
          data: { data },
        } = await axios(
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}/industries`,
          {
            withCredentials: true,
          }
        );

        this.industries = data;

        this.refreshConfig();
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },

    refreshConfig() {
      this.config = defaultsDeep(this.config, {
        excludeRules: [],
        caps: {
          discountRateCap: 0.02,
          txnFeeCap: 0.3,
          smpMonthlyFee: 72,
          smpTxnFee: 0.04,
          asmpTxnFee: 0.3,
        },
        feeRules: VOLUME_RANGES.reduce((acc, val) => {
          acc[val] = 'Txn Fee';
          return acc;
        }, {}),
        industries: this.industries.reduce((acc, industry) => {
          acc[industry] = {
            volumeRanges: VOLUME_RANGES.reduce((acc2, range) => {
              acc2[range] = {
                minBPSJump: 0,
                minDecileJump: 0,
                decileCeiling: 0,
              };
              return acc2;
            }, {}),
          };
          return acc;
        }, {}),
      });
    },

    async updateStatus(status) {
      const alertMessage = {
        title: 'Wave Update Error',
        body: `Iteration: ${this.$route.params.waveIterationId}`,
      };

      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}`,
          {
            status,
          },
          {
            withCredentials: true,
          }
        );
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },

    async saveConfig() {
      const alertMessage = {
        title: 'Config Save Error',
        body: `Iteration: ${this.$route.params.waveIterationId}`,
      };

      if (!this.hasEditableStatus) {
        return;
      }
      if (JSON.stringify(this.config) === this.waveIteration.config) {
        return;
      }
      try {
        await axios.put(
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}`,
          {
            config: JSON.stringify(this.config),
          },
          {
            withCredentials: true,
          }
        );
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },

    async deleteWaveIteration() {
      const confirmTitle = 'Confirm Deletion';
      const confirmBody = `Are you sure you want to delete: ${this.$route.params.waveIterationId}?`;
      const successfulDeletion = `Wave ${this.$route.params.waveIterationId}} successfully deleted.`;
      const shouldContinue = await displayConfirmAlert(confirmTitle, confirmBody);
      if (!shouldContinue) {
        return;
      }
      this.isDeleting = true;
      const alertMessage = {
        title: 'Error Deleting Wave',
        body: `An error occured when deleting Wave:\n\n${this.$route.params.waveIterationId}\n\nPlease try again later.`,
      }

      try {
        await axios.delete(`${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}`, {
          withCredentials: true,
        });
        await displayDeletionToast(successfulDeletion);
        this.$router.push({ name: 'Home' });
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      } finally {
        this.isDeleting = false;
      }
    },

    async pullNewValues() {
      const alertMessage = {
        title: 'Pulling Error',
        body: `Failed to pull updated numbers for:\n${this.$route.params.waveIterationId}\n\nPlease try again later.`,
      };

      try {
        await axios.post(
          `${process.env.VUE_APP_API_URL}/waveIterations/${this.$route.params.waveIterationId}/pullNewValues`,
          {},
          {
            withCredentials: true,
          }
        );

        this.listWaveIterationMerchants();
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },
    statusToVariant,
    async downloadActiveIndustryCSV() {
      const alertMessage = {
        title: 'Missing Merchant Data',
        body: 'Unable to export to CSV at this time.\n\nPlease try again later.',
      };

      if (this.waveMerchants.length <= 0) {
        const error = new Error(alertMessage.title);
        this.displayNavAlertError({ error, alertMessage });
      }
      downloadCSVFromMerchants(this.calculated.merchants);
    },
    numbertoFloat(data) {
      if (!data) {
        return data;
      }
      return Number.parseFloat(data).toFixed(2);
    },
  },
};
</script>

<style lang="scss">
.sidebar-width {
  width: 300px;
}
.config-settings {
  flex: 0 0 55px;
  width: 100%;
}
</style>
