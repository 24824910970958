<template>
  <div class="flex-fill container py-5">
    <div class="accordion bg-white rounded-lg overflow-hidden shadow" v-if="waves.length" role="tablist">
      <div v-for="wave in waves" :key="wave.id">
        <div
          class="d-flex p-4 justify-content-between align-items-center cursor-pointer"
          @click="toggleExpanedWaveIteration(wave.id)"
        >
          <div class="d-flex flex-column">
            <div class="font-weight-bold text-primary font-size-2xl">{{ wave.name }}</div>
            <div class="text-muted font-size-md"></div>
          </div>
          <div class="d-flex">
            <div class="mr-3 text-muted">{{ wave.waveIterations.length }} Wave Iterations</div>
            <div class="text-muted">
              <b-icon-chevron-up v-if="expandedWaveIteration === wave.id"></b-icon-chevron-up>
              <b-icon-chevron-down v-else></b-icon-chevron-down>
            </div>
          </div>
        </div>
        <hr class="border-top my-0 mx-4" />
        <b-collapse :visible="expandedWaveIteration === wave.id" accordion="my-accordion" role="tabpanel">
          <div
            @click="onCreateWaveIteration({ waveId: wave.id, sourceWaveIterationId: null })"
            class="
              cursor-pointer
              text-center
              px-4
              pt-2
              pb-0
              mt-2
              font-size-xl
              d-flex
              align-items-left
              justify-content-left
            "
          >
            <b-icon icon="plus" font-scale="2" variant="primary"></b-icon>
            <p class="mt-1">Wave Iteration</p>
            <p></p>
          </div>
          <WaveIterationListViewItem
            @onCreateWaveIteration="onCreateWaveIteration"
            v-for="waveIteration in wave.waveIterations"
            :key="waveIteration.id"
            :wave-iteration="waveIteration"
          />
        </b-collapse>
      </div>
    </div>

    <b-modal v-model="createModalVisible" size="xl" centered hide-footer hide-header>
      <CreateWaveIteration
        v-if="createModalVisible"
        :wave-id="newWaveIterationParams.waveId"
        :source-wave-iteration-id="newWaveIterationParams.sourceWaveIterationId"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import axios from 'axios';
import WaveIterationListViewItem from '../components/WaveIterationListViewItem.vue';
import CreateWaveIteration from '../components/CreateWaveIteration.vue';

export default {
  name: 'Home',
  components: {
    WaveIterationListViewItem,
    CreateWaveIteration,
  },
  mounted() {
    this.listWaveIterations();
  },
  data() {
    return {
      expandedWaveIteration: null,
      waves: [],
      newWaveIterationParams: null,
      createModalVisible: false,
    };
  },
  methods: {
    ...mapActions('errors', ['displayNavAlertError']),
    formatWaves(localWaves, localWaveIterations) {
      const finalWaves = localWaves
        .map((wave) => ({
          ...wave,
          waveIterations: localWaveIterations
            .filter((waveIteration) => !waveIteration.sourceWaveIterationId && waveIteration.waveId === wave.id)
            .map((waveIteration) => formatSubWaveIterations(waveIteration, localWaveIterations)),
        }))
        .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

      return finalWaves;

      function formatSubWaveIterations(currentWaveIteration, allWaveIterations) {
        return {
          ...currentWaveIteration,
          subWaveIterations: allWaveIterations
            .filter((waveIteration) => waveIteration.sourceWaveIterationId === currentWaveIteration.id)
            .map((waveIteration) => formatSubWaveIterations(waveIteration, allWaveIterations)),
        };
      }
    },
    async listWaveIterations() {
      const alertMessage = {
        title: 'Wave Iterations Error',
        body: 'Unable to list Wave iterations.',
      };

      try {
        const {
          data: { data: waveIterations },
        } = await axios(`${process.env.VUE_APP_API_URL}/waveIterations`, {
          withCredentials: true,
        });
        const {
          data: { data: waves },
        } = await axios(`${process.env.VUE_APP_API_URL}/waves`, {
          withCredentials: true,
        });

        this.waves = this.formatWaves(waves, waveIterations);
      } catch (err) {
        this.displayNavAlertError({ err, alertMessage });
      }
    },
    onCreateWaveIteration(newWaveIterationParams) {
      this.newWaveIterationParams = newWaveIterationParams;
      this.createModalVisible = true;
    },
    toggleExpanedWaveIteration(waveIterationId) {
      this.expandedWaveIteration = this.expandedWaveIteration === waveIterationId ? null : waveIterationId;
    },
  },
};
</script>

<style lang="scss">
.wave-iteration {
  transition: all 100ms ease;
}
.wave-iteration:hover {
  background-color: white;
}
.text-decoration-none {
  text-decoration: none;
}
</style>
