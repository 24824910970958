import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { applyPolyfills, defineCustomElements } from '@heartlandone/vega/loader';
import App from './App.vue';
import router from './router';
import store from './store';

import '@heartlandone/vega/style/vega.css';
import './styles/app.scss';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;
Vue.config.ignoredElements = [/vega-\w*/];

applyPolyfills().then(() => {
  defineCustomElements();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
