<template>
  <div class="h-100 flex-fill d-flex align-items-center justify-content-center" style="min-height: 90vh">
    <div class="border d-flex flex-column p-5 rounded bg-white align-items-center">
      <h1 class="mb-3 font-weight-bold">Forbidden</h1>
      <span class="mb-4">You do not have access to this</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Forbidden',
  components: {},
};
</script>
