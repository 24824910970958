import axios from 'axios';
import baseRequestStore, { STATE_IN_PROGRESS, STATE_SUCCESS, STATE_FAIL } from '../baseRequestStore';

export default {
  ...baseRequestStore,
  actions: {
    async sendRequest({ commit }) {
      commit('reset');
      commit('setRequestState', STATE_IN_PROGRESS);

      try {
        const response = await axios(`${process.env.VUE_APP_API_URL}/auth`, { withCredentials: true });

        commit('setRequestState', STATE_SUCCESS);
        commit('setData', response.data.data);
        commit('auth/setUser', response.data.data, { root: true });
      } catch (error) {
        commit('setRequestState', STATE_FAIL);
        commit('setError', error?.response?.data?.message);
        commit('auth/clearUser', null, { root: true });
      }
    },
  },
};
