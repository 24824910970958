import swal from 'sweetalert2';

export default async function displayConfirmAlert(title, body) {
  const { isConfirmed } = await swal.fire({
    title,
    text: body,
    icon: 'warning',
    confirmButtonText: 'Confirm',
    showCancelButton: true,
    showCloseButton: true,
    cancelButtonText: 'Cancel',
  });
  return isConfirmed;
}

const deletionToast = swal.mixin({
  toast: true,
  showConfirmButton: false,
  timer: 4000,
  timerProgressBar: true,
});

export async function displayDeletionToast(title) {
  deletionToast.fire({
    icon: 'success',
    title,
  });
}
