import _ from 'lodash';

export const CONTAINS_OP = 'contains';
export const NOT_CONTAINS_OP = 'does not contain';
export const IS_ONE_OP = 'is one of';
export const IS_NOT_ONE_OP = 'is not one of';
export const EQUALS_OP = 'equals';
export const NOT_EQUALS_OP = 'does not equal';
export const LESS_OP = 'less than';
export const GREATER_OP = 'greater than';

export default class GroupRules {
  constructor(measurementKey = 'id') {
    this.measurementKey = measurementKey;
  }

  static isValidRule(rule) {
    if (!rule ||
      !rule.conditions ||
      rule.conditions.length === 0 ||
      !rule.result) {
      return false
    }
    return _.every(rule.conditions, condition => condition.key && condition.operation && condition.value !== null && condition.value !== undefined);
  }

  run(rules, groups, items) {
    if (!groups || groups.length === 0) {
      throw new Error ('missing groups');
    }
    if (!items || items.length === 0) {
      throw new Error ('missing items');
    }

    // grouped chain fields
    const merchantId = 'merchantId';
    const merchantName = 'merchantName';
    const isSMP = 'isSMP';
    const isASMP = 'isASMP';
    const isPetroPay = 'isPetroPay';

    // reset measurements
    const measurements = [];

    // find default group
    let defaultGroup = _.find(groups, {default: true});
    if (!defaultGroup) {
      [defaultGroup] = groups;
    }

    // eslint-disable-next-line prefer-object-spread
    const groupings = Object.assign({}, ..._.map(items, item => ({[item[this.measurementKey]]: defaultGroup.value})));
    if (!rules || rules.length === 0) {
      takeMeasurement();
      return measurements;
    }

    function ruleForMatchingItems(merchantItem, { operation: conditionOperation, value: conditionValue }) {
      switch(conditionOperation) {
        case EQUALS_OP:
          return merchantItem === conditionValue || merchantItem === Number.parseInt(conditionValue, 10);

        case NOT_EQUALS_OP:
          if (typeof merchantItem === 'string') {
            return merchantItem !== conditionValue;
          }
          return merchantItem !== conditionValue && merchantItem !== Number.parseInt(conditionValue, 10);

        case CONTAINS_OP:
          if (typeof merchantItem === 'string') {
            return merchantItem.toLowerCase().includes(conditionValue.toLowerCase());
          }
          return merchantItem === conditionValue || merchantItem === Number.parseInt(conditionValue, 10);

        case NOT_CONTAINS_OP:
          if (typeof merchantItem === 'string') {
            return !merchantItem.toLowerCase().includes(conditionValue.toLowerCase());
          }
          return merchantItem !== conditionValue || merchantItem !== Number.parseInt(conditionValue, 10); 

        case IS_ONE_OP:
          if (typeof merchantItem === 'string') {
            return conditionValue.split(',').map(str => str.toLowerCase().trim()).indexOf(merchantItem.toLowerCase()) > -1;
          }
          return conditionValue.split(',').map(con => Number.parseInt(con, 10)).filter(str => merchantItem === str).length > 0;

        case IS_NOT_ONE_OP:
          if (typeof merchantItem === 'string') {
            return conditionValue.split(',').map(str => str.toLowerCase().trim()).indexOf(merchantItem.toLowerCase()) === -1;
          }
          return conditionValue.split(',').map(con => Number.parseInt(con, 10)).filter(str => merchantItem !== str).length > 0;

        case LESS_OP:
          if (merchantItem < Number.parseInt(conditionValue, 10)) {
            return true;
          }
          return false;

        case GREATER_OP:
          if (merchantItem > Number.parseInt(conditionValue, 10)) {
            return true;
          }
          return false;

        default:
          return false;
      }
    };

    // groupRules
    const equalsAndInArray = [EQUALS_OP, CONTAINS_OP, IS_ONE_OP];

    // run rules
    for (let i = 0; i < rules.length; i+=1) {
      const rule = rules[i];
      if (!rule.enabled) {
        takeMeasurement();
        // eslint-disable-next-line no-continue
        continue;
      }

      if (!GroupRules.isValidRule(rule)) {
        break;
      }

      const matchingItems = _.map(
        _.filter(items, item => _.every(rule.conditions, condition => {
          let itemValueArray = [];
          const itemValue = (item[condition.key] === null || item[condition.key] === undefined) ? '' : item[condition.key];
          
          // boolean fields
          if (condition.key === isSMP || condition.key === isASMP || condition.key === isPetroPay) {
            return ruleForMatchingItems(itemValue.toString(), condition);
          }

          if (condition.key !== merchantId && condition.key !== merchantName) {
            return ruleForMatchingItems(itemValue, condition);
          }

          // merchantId in chain are grouped and eperated with  --> ,
          if (condition.key === merchantId) {
            itemValueArray = itemValue.split(',');
          }

          // merchantName in chain are grouped and seperated with --> ||
          if (condition.key === merchantName) {
            itemValueArray = itemValue.split(' || ');
          }

          if (itemValueArray.length === 1) {
            return ruleForMatchingItems(itemValue, condition);
          }

          if (equalsAndInArray.indexOf(condition.operation) >- 1) {
            return _.some(itemValueArray, subItem => ruleForMatchingItems(subItem, condition));
          }

          return _.every(itemValueArray, subItem => ruleForMatchingItems(subItem, condition));
        })),
        this.measurementKey
      );

      matchingItems.forEach(item => {
        groupings[item] = rule.result;
      });

      takeMeasurement();
    }

    return measurements;

    function takeMeasurement() {
      measurements.push({
       ...Object.assign({}, ..._.map(
          groups,
          group => ({[group.value]: _.filter(
            Object.keys(groupings),
            groupingKey => groupings[groupingKey] === group.value
          )}),
        )),
      });
    }
  }
}