import _ from 'lodash';


export const maxFlag = 'Review: Max discount rate and max txn fee reached.';
export const monthlyFeeASMPFlag = 'Review (ASMP): Max txn fee reached, remainder added to monthly fee.';
export const chainValueMismatchFlag = 'Review: txn fee and/or discount rate do not match other merchants with this chainId';
export const petroPayValueMismatchFlag = 'Review: txn fee and/or discount rate do not match other merchants with this petroPayId';

/* eslint-disable no-param-reassign */
export function statusToVariant(status) {
  switch (status) {
    case 'legacy':
      return 'warning';
    case 'draft':
      return 'secondary';
    case 'submitted':
      return 'primary';
    case 'approved':
      return 'success';
    case 'executed':
      return 'success';
    default:
      return 'secondary';
  }
}

const ROLE_VALUE_MAP = {
  guest: 0,
  user: 1,
  admin: 2,
  superadmin: 3,
};

export function hasAccess(sourceRole, targetRole) {
  return ROLE_VALUE_MAP[sourceRole] >= ROLE_VALUE_MAP[targetRole];
}

export const VOLUME_RANGES = [
  '$50k or less',
  '$50k - $100k',
  '$100k - $300k',
  '$300k - $500k',
  '$500k - $750k',
  '$750k - $1m',
  '$1m - $1.5m',
  '$1.5m - $2m',
  '$2m - $5m',
  '$5m or more',
];

export const DECILE_GROUP_LABELS = new Array(10).fill(undefined).map((val, idx) => `Decile ${idx + 1}`);

function median(values) {
  const sorted = values.sort((a,b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  if (sorted.length % 2) {
    // odd
    return values[mid];
  }
  // even
  return (values[mid - 1] + values[mid]) / 2.0;
}

export function getVolumeRange(volume) {
  if (volume < 50000) {
    return '$50k or less';
  }
  if (volume < 100000) {
    return '$50k - $100k';
  }
  if (volume < 300000) {
    return '$100k - $300k';
  }
  if (volume < 500000) {
    return '$300k - $500k';
  }
  if (volume < 750000) {
    return '$500k - $750k';
  }
  if (volume < 1000000) {
    return '$750k - $1m';
  }
  if (volume < 1500000) {
    return '$1m - $1.5m';
  }
  if (volume < 2000000) {
    return '$1.5m - $2m';
  }
  if (volume < 5000000) {
    return '$2m - $5m';
  }

  return '$5m or more';
}

function getIntialVolumeRanges() {
  return VOLUME_RANGES.reduce((acc, volumeRange) => {
    acc[volumeRange] = {
      minBpsBefore: Infinity,
      minBpsAfter: Infinity,
      maxBpsBefore: -Infinity,
      maxBpsAfter: -Infinity,
      decileJump: null,
      merchants: [],
      decileGroupsBefore: [
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
      ],
      decileGroupsAfter: [
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
        { count: 0, minRange: 0, maxRange: 0, merchants: [], },
      ],
      revenueBefore: 0,
      revenueAfter: 0,
    };
    return acc;
  }, {});
}

function volumeRangeDecileJump(maxBpsBefore, minBpsBefore) {
  return (maxBpsBefore - minBpsBefore) / 9;
}

export function round(num, decimalPlaces = 2) {
  const val = 10 ** decimalPlaces;
  return Math.round(num * val) / val;
}

export function calculateBPSFromRevenue(revenue, volumeAmount) {
  return (revenue / volumeAmount) * 10000;
}

function calculateRevenueFromBPS(BPS, volumeAmount) {
  return (BPS / 10000) * volumeAmount;
}

function calculateDeltaRevenueFromDeltaDiscountRate(originalDiscountRate, proposedDiscountRate, volumeAmount) {
  return (proposedDiscountRate - originalDiscountRate) * volumeAmount;
}

function calculateDeltaRevenueFromDeltaTxnFee(originalTxnFee, proposedTxnFee, volumeCount) {
  return (proposedTxnFee - originalTxnFee) * volumeCount;
}

export function generateSMPProposedValues(
  merchantOriginalTxnFee,
  merchantOriginalMonthlyFee,
  merchantVolumeCount,
  merchantVolumeAmount,
  merchantOriginalBps,
  SMPOptionsTxnFee,
  SMPOptionsMonthlyFee,
){
  const txnFee = Math.max(merchantOriginalTxnFee, SMPOptionsTxnFee);
  const monthlyFee = Math.max(merchantOriginalMonthlyFee, SMPOptionsMonthlyFee);

  let deltaRevenue = 0;
  // txn fee
  deltaRevenue += merchantVolumeCount * (txnFee - merchantOriginalTxnFee);
  // monthly fee
  deltaRevenue += ( (monthlyFee * 12) - (merchantOriginalMonthlyFee * 12) );

  // convert delta revenue to BPS and add.
  const BPS = calculateBPSFromRevenue(deltaRevenue, merchantVolumeAmount) + merchantOriginalBps;

  return {BPS, txnFee, monthlyFee}
}

export function generateProposedFees(
  revenueAfter,
  revenueBefore,
  volumeAmount,
  volumeCount,
  originalDiscountRate,
  originalTxnFee,
  originalMonthlyFee,
  preferDiscountRate,
  isASMP,
  discountRateCapEnabled,
  discountRateCap,
  txnFeeCapEnabled,
  txnFeeCap,
  ASMPTxnFeeCap,
) {
  function calculateDiscountRate(localDeltaRevenue, localVolumeAmount, localOriginalDiscountRate) {
    return (localDeltaRevenue / localVolumeAmount) + localOriginalDiscountRate;
  }

  function calculateTxnFee(localDeltaRevenue, localVolumeCount, localOriginalTxnFee) {
    return (localDeltaRevenue / localVolumeCount) + localOriginalTxnFee;
  }

  function calculateMonthlyFee(localDeltaRevenue, localOriginalMonthlyFee) {
    const yearlyFee = localOriginalMonthlyFee * 12.0;
    return (yearlyFee + localDeltaRevenue) / 12.0;
  }

  function calculateRemainingRevenueFromTxnFee(localVolumeCount, localTxnFee, localOriginalTxnFee, localRevenue) {
        const revenueChanged = localVolumeCount * (localTxnFee - localOriginalTxnFee);
        return localRevenue - revenueChanged;
  }

  function calculateRemainingRevenueFromDiscountRate(localVolumeAmount, localDiscountRate, localOriginalDiscountRate, localRevenue) {
      const revenueChanged = localVolumeAmount * (localDiscountRate - localOriginalDiscountRate);
      return localRevenue - revenueChanged;
  }

  const deltaRevenue = revenueAfter - revenueBefore;
  // percentage
  const maxDiscountRate = originalDiscountRate * (1 + discountRateCap);
  // dollar amount
  const maxTxnFee = originalTxnFee + txnFeeCap;
  const maxASMPTxnFee = originalTxnFee + ASMPTxnFeeCap;

  const flag = [];
  let newDiscountRate = originalDiscountRate;
  let newTxnFee = originalTxnFee;
  let newMonthlyFee = originalMonthlyFee || 0.0;
  let finalProposedBps = calculateBPSFromRevenue(revenueAfter, volumeAmount);
  let finalRevenueAfter = revenueAfter;

  if (preferDiscountRate && !isASMP) {
    newDiscountRate = calculateDiscountRate(deltaRevenue, volumeAmount, originalDiscountRate);
    if (newDiscountRate > maxDiscountRate && discountRateCapEnabled) {
      newDiscountRate = maxDiscountRate;
      let remainingRevenue = calculateRemainingRevenueFromDiscountRate(volumeAmount, newDiscountRate, originalDiscountRate, deltaRevenue);

      newTxnFee = calculateTxnFee(remainingRevenue, volumeCount, originalTxnFee);
      if (newTxnFee > maxTxnFee && txnFeeCapEnabled) {
        flag.push(maxFlag);
        newTxnFee = maxTxnFee;

        remainingRevenue = calculateRemainingRevenueFromTxnFee(volumeCount, newTxnFee, originalTxnFee, remainingRevenue);
        finalRevenueAfter = revenueAfter - remainingRevenue;
        finalProposedBps = calculateBPSFromRevenue(finalRevenueAfter, volumeAmount);
      }
    }
  } else {
    newTxnFee = calculateTxnFee(deltaRevenue, volumeCount, originalTxnFee);
    const localMaxTxnFee = isASMP ? maxASMPTxnFee : maxTxnFee;
    if (newTxnFee > localMaxTxnFee && txnFeeCapEnabled) {
      newTxnFee = localMaxTxnFee;
      let remainingRevenue = calculateRemainingRevenueFromTxnFee(volumeCount, localMaxTxnFee, originalTxnFee, deltaRevenue)

      if (isASMP) {
        newMonthlyFee = calculateMonthlyFee(remainingRevenue, originalMonthlyFee);
        flag.push(monthlyFeeASMPFlag);
      } else {
        newDiscountRate = calculateDiscountRate(remainingRevenue, volumeAmount, originalDiscountRate);
        if (newDiscountRate > maxDiscountRate && discountRateCapEnabled) {
          flag.push(maxFlag);
          newDiscountRate = maxDiscountRate;

          remainingRevenue = calculateRemainingRevenueFromDiscountRate(volumeAmount, newDiscountRate, originalDiscountRate, remainingRevenue);
          finalRevenueAfter = revenueAfter - remainingRevenue;
          finalProposedBps = calculateBPSFromRevenue(finalRevenueAfter, volumeAmount);
        }
      }
    }
  }

  return {
    discountRate: newDiscountRate,
    txnFee: newTxnFee,
    monthlyFee: newMonthlyFee,
    flag,
    proposedBps: finalProposedBps,
    revenueAfter: finalRevenueAfter,
  };
}

function getProposedBPS(merchant, volumeRange, volumeRangeConfig) {
  const decileCeiling = Number.parseInt(volumeRangeConfig.decileCeiling, 10) || 0;
  const minDecileJump = Number.parseInt(volumeRangeConfig.minDecileJump, 10) || 0;
  const minBPSJump = Number.parseInt(volumeRangeConfig.minBPSJump, 10) || 0;

  if (merchant.decileBefore >= decileCeiling) {
    return merchant.originalBps + minBPSJump;
  }
  // minDecileJump
  let newDecile = merchant.decileBefore + minDecileJump;

  // decileCeiling
  newDecile = Math.min(newDecile, decileCeiling);

  let newBPS = (newDecile * volumeRange.decileJump) + volumeRange.minBpsBefore + 0.01;

  // minBPSJump
  newBPS = Math.max(newBPS, merchant.originalBps + minBPSJump);

  // never decrease
  newBPS = Math.max(newBPS, merchant.originalBps);

  return newBPS;
}

export function getMerchantDecile(merchantOriginalBPS, volumeRangeMinBPSBefore, currentVolumeRangeDecileJump, limitToNine=true) {
  let decile = Math.floor((merchantOriginalBPS - volumeRangeMinBPSBefore) / currentVolumeRangeDecileJump);
  if (decile < 0) {
    decile = 0;
  }
  if (limitToNine) {
    return Math.min(decile , 9);
  }

  return decile;
}

function decileGroupRange(currentVolumeRangeMinBpsBefore, currentIndex, currentVolumeRangeDecileJump) {
  return {
    min: Number.parseFloat(currentVolumeRangeMinBpsBefore + currentIndex * currentVolumeRangeDecileJump).toFixed(2),
    max: Number.parseFloat(currentVolumeRangeMinBpsBefore + (currentIndex + 1) * currentVolumeRangeDecileJump).toFixed(
      2
    ),
  };
}

export function calculateDecileGroups(waveIterationMerchants, industry, industryConfig, feeRules, discountRateCapEnabled, discountRateCap, txnFeeCapEnabled, txnFeeCap, smpMonthlyFee, smpTxnFee, asmpTxnFeeCap) {
  let flaggedMerchantCount = 0;
  let changeInRevenue = 0;
  let affectedMerchants = 0;
  let allMerchants = 0;
  const volumeRanges = getIntialVolumeRanges();

  // Determine min/max bps for each volume range
  waveIterationMerchants.forEach((merchant) => {
    // Filter out any merchants that don't have the information we need
    if (merchant.volumeAmount === null || merchant.originalBps === null || merchant.revenue === null) {
      return;
    }

    const volumeRange = getVolumeRange(merchant.volumeAmount);
    volumeRanges[volumeRange].minBpsBefore = Math.min(volumeRanges[volumeRange].minBpsBefore, merchant.originalBps);
    volumeRanges[volumeRange].maxBpsBefore = Math.max(volumeRanges[volumeRange].maxBpsBefore, merchant.originalBps);

    volumeRanges[volumeRange].merchants.push({
      ...merchant,
      volumeRange,
    });
  });

  Object.keys(volumeRanges).forEach((volumeRangeKey) => {
    const volumeRange = volumeRanges[volumeRangeKey];

    const volumeRangeConfig = industryConfig.volumeRanges[volumeRangeKey];
    if (volumeRange.minBpsBefore === volumeRange.maxBpsBefore) {
      // Temporary(?) workaround for when there's only one merchant in a volume range
      volumeRange.maxBpsBefore = volumeRange.minBpsBefore + 100;
    }
    volumeRange.decileJump = volumeRangeDecileJump(volumeRange.maxBpsBefore, volumeRange.minBpsBefore);
    // decileBefore will set its minRange and maxRange to NaN when no merchants belong to the decile group
    volumeRange.decileGroupsBefore.forEach((decileGroup, index) => {
      const range = decileGroupRange(volumeRange.minBpsBefore, index, volumeRange.decileJump);
      decileGroup.minRange = range.min;
      decileGroup.maxRange = range.max;
    });
    // decileAfter will set its minRange and maxRange to NaN when no merchants belong to the decile group
    volumeRange.decileGroupsAfter.forEach((decileGroup, index) => {
      const range = decileGroupRange(volumeRange.minBpsBefore, index, volumeRange.decileJump);
      decileGroup.minRange = range.min;
      decileGroup.maxRange = range.max;
    });

    volumeRange.merchants.forEach((merchant) => {
      allMerchants += 1;
      merchant.decileBefore = getMerchantDecile(
        merchant.originalBps,
        volumeRange.minBpsBefore,
        volumeRange.decileJump
      );

      if (merchant.isSMP) {
        // SMP merchants
        const {BPS, txnFee, monthlyFee} = generateSMPProposedValues(
          merchant.originalTxnFee,
          merchant.originalMonthlyFee && !Number.isNaN(merchant.originalMonthlyFee) ? merchant.originalMonthlyFee : 0,
          merchant.volumeCount,
          merchant.volumeAmount,
          merchant.originalBps,
          smpTxnFee,
          smpMonthlyFee,
        );
        merchant.proposedBps = BPS;
        merchant.revenueAfter = calculateRevenueFromBPS(merchant.proposedBps, merchant.volumeAmount);
        merchant.proposedTxnFee = txnFee;
        merchant.proposedTxnFeeIncreaseBy = round((txnFee - merchant.originalTxnFee), 4);
        merchant.proposedMonthlyFee = monthlyFee;
        // discount rate does not change.
        merchant.proposedDiscountRate = merchant.originalDiscountRate;
        // discount rate does not change and proposed discount rate should increase by zero
        merchant.proposedDiscountRateIncreaseBy = 0;

        changeInRevenue += (merchant.revenueAfter - merchant.revenue);

        affectedMerchants = round(BPS, 10) !== round(merchant.originalBps, 10) ? affectedMerchants + 1 : affectedMerchants;

        merchant.decileAfter = getMerchantDecile(
          merchant.proposedBps,
          volumeRange.minBpsBefore,
          volumeRange.decileJump
        );
      } else {
        merchant.proposedBps = getProposedBPS(merchant, volumeRange, volumeRangeConfig);
        merchant.revenueAfter = calculateRevenueFromBPS(merchant.proposedBps, merchant.volumeAmount);

        const {discountRate, txnFee, flag, monthlyFee, proposedBps, revenueAfter} = generateProposedFees(
          merchant.revenueAfter,
          merchant.revenue,
          merchant.volumeAmount,
          merchant.volumeCount,
          merchant.originalDiscountRate,
          merchant.originalTxnFee,
          merchant.originalMonthlyFee,
          feeRules[volumeRangeKey] === 'Discount Rate',
          merchant.isASMP,
          discountRateCapEnabled,
          discountRateCap,
          txnFeeCapEnabled,
          txnFeeCap,
          asmpTxnFeeCap,
        );

        merchant.proposedBps = proposedBps;
        merchant.revenueAfter = revenueAfter;
        merchant.decileAfter = getMerchantDecile(
          merchant.proposedBps,
          volumeRange.minBpsBefore,
          volumeRange.decileJump
        );

        if (flag.length > 0) {
          merchant.flag = flag;
          flaggedMerchantCount += 1;
        }

        merchant.proposedTxnFee = txnFee;
        merchant.proposedTxnFeeIncreaseBy = round((txnFee - merchant.originalTxnFee), 4);
        merchant.proposedDiscountRate = discountRate;
        merchant.proposedDiscountRateIncreaseBy = round((discountRate - merchant.originalDiscountRate), 6);
        merchant.proposedMonthlyFee = monthlyFee;
        
        affectedMerchants = round(merchant.proposedBps, 10) !== round(merchant.originalBps, 10) ? affectedMerchants + 1 : affectedMerchants;
        changeInRevenue += (merchant.revenueAfter - merchant.revenue);
      }

      if (!Number.isNaN(merchant.decileBefore) && !Number.isNaN(merchant.decileAfter)) {
        volumeRange.decileGroupsBefore[merchant.decileBefore].count += 1;
        volumeRange.decileGroupsAfter[merchant.decileAfter].count += 1;
        volumeRange.decileGroupsBefore[merchant.decileBefore].merchants.push(merchant);
        volumeRange.decileGroupsAfter[merchant.decileAfter].merchants.push(merchant);
      }
    });
  });

  const industryRevenueBefore = Object.values(volumeRanges).reduce((acc, v) => acc + (v.merchants.reduce((acc2, m) => acc2 + m.revenue, 0)), 0);
  const industryRevenueAfter = Object.values(volumeRanges).reduce((acc, v) => acc + (v.merchants.reduce((acc2, m) => acc2 + m.revenueAfter, 0)), 0);
  const industryVolume = Object.values(volumeRanges).reduce((acc, v) => acc + (v.merchants.reduce((acc2, m) => acc2 + m.volumeAmount, 0)), 0);
  let bpsDelta = ((industryRevenueAfter - industryRevenueBefore) / industryVolume) * 10000;
  if (Number.isNaN(bpsDelta)) {
    bpsDelta = 0;
  }

  let merchants = [];
  Object.values(volumeRanges).forEach((volumeRange) => {
    merchants = merchants.concat(volumeRange.merchants);
  });

  return {
    industry,
    merchants,
    decileGroupsBefore: Object.values(volumeRanges).map((v) => v.decileGroupsBefore),
    decileGroupsAfter: Object.values(volumeRanges).map((v) => v.decileGroupsAfter),
    industryRevenueBefore,
    industryRevenueAfter,
    revenueChangePercent: Math.round(((industryRevenueAfter - industryRevenueBefore) / industryRevenueBefore) * 100),

    meanProposedTxnFees: Object.values(volumeRanges).map(
      (volumeRange) => {
        const merchantsToCount = _.filter(volumeRange.merchants, (merchant) => !merchant.isSMP && !merchant.isASMP);
        const value = _.meanBy(merchantsToCount, merchant => Number.parseFloat(merchant.proposedTxnFee));
        return {
          count: _.filter(merchantsToCount, m => m.proposedTxnFee > value).length,
          value: round(value, 2),
        };
      }
    ),
    meanProposedDiscountRates: Object.values(volumeRanges).map(
      (volumeRange) => {
        const merchantsToCount = _.filter(volumeRange.merchants, (merchant) => !merchant.isSMP && !merchant.isASMP);
        const value = _.meanBy(merchantsToCount, merchant => Number.parseFloat(merchant.proposedDiscountRate));
        return {
          count: _.filter(merchantsToCount, m => m.proposedDiscountRate > value).length,
          value: round(value, 4),
        };
      }
    ),
    medianProposedTxnFees: Object.values(volumeRanges).map(
      (volumeRange) => {
        const merchantsToCount = _.filter(volumeRange.merchants, (merchant) => !merchant.isSMP && !merchant.isASMP);
        const value = median(merchantsToCount.map(merchant => Number.parseFloat(merchant.proposedTxnFee)));
        return {
          count: _.filter(merchantsToCount, m => m.proposedTxnFee > value).length,
          value: round(value, 2),
        };
      }
    ),
    medianProposedDiscountRates: Object.values(volumeRanges).map(
      (volumeRange) => {
        const merchantsToCount = _.filter(volumeRange.merchants, (merchant) => !merchant.isSMP && !merchant.isASMP);
        const value = median(merchantsToCount.map(merchant => Number.parseFloat(merchant.proposedDiscountRate)));
        return {
          count: _.filter(merchantsToCount, m => m.proposedDiscountRate > value).length,
          value: round(value, 4),
        };
      }
    ),
    bpsDelta,
    flaggedMerchantCount,
    changeInRevenue,
    affectedMerchants: {
      affectedMerchants,
      allMerchants,
    },
  };
}

export function groupedMerchantsAndKey(merchants, groupByKey) {
  const merchantGroups = _.groupBy(merchants, groupByKey);
  const merchantsGroupKeys = Object.keys(merchantGroups).filter(
    (key) => key && key !== 'null' && key !== 'undefined' && merchantGroups[key].length > 1
  );
  return { merchantGroups, merchantsGroupKeys };
}

export function txnFeeAndDiscountRateMatch(merchantGroup, merchant) {
  return _.every(merchantGroup, {
    originalTxnFee: merchant.originalTxnFee,
    originalDiscountRate: merchant.originalDiscountRate,
  });
}

export function groupChains(merchants, chainOidKey) {
  let merchantsCopy = JSON.parse(JSON.stringify(merchants));
  const { merchantGroups, merchantsGroupKeys } = groupedMerchantsAndKey(merchants, chainOidKey);

  const groupedMerchants = merchantsGroupKeys.map((key) => {
    const firstMerchant = merchantGroups[key][0];
    const maxDiscountRate = _.maxBy(merchantGroups[key], 'originalDiscountRate').originalDiscountRate;
    const maxTxnFee = _.maxBy(merchantGroups[key], 'originalTxnFee').originalTxnFee;

    let flag = _.uniq(_.flatten(merchantGroups[key].map(item => item.flag || [])));
    if (!txnFeeAndDiscountRateMatch(merchantGroups[key], firstMerchant)) {
      flag = [chainValueMismatchFlag].concat(flag);
    }

    const revenue = merchantGroups[key].reduce((partialSum, merchant) => partialSum + merchant.revenue, 0);
    const volumeAmount = merchantGroups[key].reduce((partialSum, merchant) => partialSum + merchant.volumeAmount, 0);

    return {
      waveIterationId: firstMerchant.waveIterationId,
      merchantId: merchantGroups[key].map((merchant) => merchant.merchantId).join(','),
      merchantName: merchantGroups[key].map((merchant) => merchant.merchantName).join(' || '),
      industry: firstMerchant.industry,
      waveId: firstMerchant.waveId,
      volumeAmount,
      volumeCount: merchantGroups[key].reduce((partialSum, merchant) => partialSum + merchant.volumeCount, 0),
      revenue,
      revenueLessPCI: merchantGroups[key].reduce(
        (partialSum, merchant) => partialSum + merchant.revenueLessPCI,
        0
      ),
      originalDiscountRate: maxDiscountRate,
      originalTxnFee: maxTxnFee,
      flag,
      [chainOidKey]: key,
      includedMerchants: merchantGroups[key],
      originalBps: calculateBPSFromRevenue(revenue, volumeAmount),
    };
  });

  // remove old merchants
  _.remove(merchantsCopy, (m) => m[chainOidKey] && merchantsGroupKeys.indexOf(m[chainOidKey].toString()) > -1);

  // add new grouped merchants
  merchantsCopy = merchantsCopy.concat(groupedMerchants);

  return merchantsCopy;
}

export function flagPetroPayAccounts(merchants, invoiceParentMsk) {
  if (!merchants || merchants.length === 0) {
    return [];
  }
  let flaggedMerchants = [];
  const flaggedMerchantsKeys = [];
  let merchantsCopy = JSON.parse(JSON.stringify(merchants));
  const { merchantGroups, merchantsGroupKeys } = groupedMerchantsAndKey(merchants, invoiceParentMsk);

  if (!merchantsGroupKeys.length) {
    return merchants;
  }

  merchantsGroupKeys.forEach(key => {
    if (!txnFeeAndDiscountRateMatch(merchantGroups[key], merchantGroups[key][0])) {
      flaggedMerchants = merchantGroups[key].map(merchant => ({
        ...merchant,
        flag: merchant.flag ? merchant.flag.concat([ petroPayValueMismatchFlag ]) : [ petroPayValueMismatchFlag ],
      }));
      flaggedMerchantsKeys.push(key);
    }
  });

  if (!flaggedMerchants.length) {
    return merchants;
  }

  // remove old merchants
  _.remove(merchantsCopy, m => m[invoiceParentMsk] && flaggedMerchantsKeys.indexOf(m[invoiceParentMsk].toString()) > -1);

  // add new flagged merchants
  merchantsCopy = merchantsCopy.concat(flaggedMerchants);

  return merchantsCopy;
}

export function downloadCSVFromMerchants(merchants) {
  const columns = [
    { display: 'Merchant Id', value: 'merchantId' },
    { display: 'Merchant Name', value: 'merchantName' },
    { display: 'Line Of Business', value: 'lineOfBusiness' },
    { display: 'Volume Amount', value: 'volumeAmount' },
    { display: 'Volume Count', value: 'volumeCount' },
    { display: 'Revenue', value: 'revenue' },
    { display: 'Original Bps', value: 'originalBps' },
    { display: 'Original Discount Rate', value: 'originalDiscountRate' },
    { display: 'Original Txn Fee', value: 'originalTxnFee' },
    { display: 'Original Monthly Fee', value: 'originalMonthlyFee' },
    { display: 'Revenue After', value: 'revenueAfter' },
    { display: 'Proposed Bps', value: 'proposedBps' },
    { display: 'Proposed Discount Rate', value: 'proposedDiscountRate' },
    { display: 'Proposed Discount Rate Increase By', value: 'proposedDiscountRateIncreaseBy' },
    { display: 'Proposed Txn Fee', value: 'proposedTxnFee' },
    { display: 'Proposed Txn Fee Increase By',  value: 'proposedTxnFeeIncreaseBy'},
    { display: 'Proposed Monthly Fee', value: 'proposedMonthlyFee' },
    { display: 'Invoice Parent MSK', value: 'invoiceParentMSK' || '' },
    { display: 'isSMP', value: 'isSMP' },
    { display: 'isASMP', value: 'isASMP' },
    { display: 'isPetroPay', value: 'isPetroPay' },
    { display: 'Flag', value: 'flag' },
  ];
  const csvStr = merchants.reduce((acc, item) => {
    if (item.volumeAmount === null || item.originalBps === null || item.REVENUE === null) {
      return acc;
    }

    let focusMerchants = [item];
    if (item.includedMerchants && item.includedMerchants.length > 0) {
      focusMerchants = item.includedMerchants.map(merchant => {
        let deltaRevenue = calculateDeltaRevenueFromDeltaDiscountRate(merchant.originalDiscountRate, item.proposedDiscountRate, merchant.volumeAmount);
        deltaRevenue += calculateDeltaRevenueFromDeltaTxnFee(merchant.originalTxnFee, item.proposedTxnFee, merchant.volumeCount);
        deltaRevenue += 12 * (item.proposedMonthlyFee - (item.originalMonthlyFee || 0));

        const revenueAfter = merchant.revenue + deltaRevenue;

        return {
          ...merchant,
          proposedDiscountRate: item.proposedDiscountRate,
          proposedDiscountRateIncreaseBy: (item.proposedDiscountRate - merchant.originalDiscountRate),
          proposedTxnFee: item.proposedTxnFee,
          proposedTxnFeeIncreaseBy: (item.proposedTxnFee - merchant.originalTxnFee),
          originalBps: calculateBPSFromRevenue(merchant.revenue, merchant.volumeAmount),
          proposedBps: calculateBPSFromRevenue(revenueAfter, merchant.volumeAmount),
          proposedMonthlyFee: item.proposedMonthlyFee,
          revenueAfter,
          flag: item.flag || '',
        }
      });
    }
    if (!item.invoiceParentMSK) {
      item.invoiceParentMSK = '';
    }
    focusMerchants.forEach(focusMerchant => {
      _.map(columns, 'value').forEach((col, index) => {
        if (index !== 0) {
          acc += ',';
        }

        if (focusMerchant[col] === undefined) {
          return;
        }

        if (focusMerchant[col] === null) {
          return;
        }

        if (typeof focusMerchant[col] === 'string') {
          acc += `"=""${focusMerchant[col]}"""`;
          return;
        }

        if (Array.isArray(focusMerchant[col])) {
          if (focusMerchant[col].length === 0) {
            return;
          }
          if (focusMerchant[col].length === 1) {
            acc += `"=""${focusMerchant[col][0]}"""`;
            return;
          }

          let arrayItemsToString = '';
          focusMerchant[col].forEach((element, idx) => {
            if (idx === 0) {
              arrayItemsToString += `${element}`;  
            } else {
              arrayItemsToString += `\n${element}`;
            }
          });
          acc += `"=""${arrayItemsToString}"""`;
          return;
        }

        acc += focusMerchant[col];
      });

      acc += '\n';
    });
    return `${acc}\n`;
  }, `${_.map(columns, 'display').join(',')}\n`);

  const blob = new Blob([csvStr]);
  const url = URL.createObjectURL(blob);
  const el = document.createElement('a');
  el.href = url;
  el.download = 'output.csv';
  el.click();
}
