import refreshUser from './refreshUser';
import logout from './logout';

export default {
  namespaced: true,

  modules: {
    refreshUser,
    logout,
  },

  state: {
    isAuth: false,
    user: null,
  },

  mutations: {
    setUser(state, user) {
      state.isAuth = true;
      state.user = user;
    },

    clearUser(state) {
      state.isAuth = false;
      state.user = null;
    },
  },
};
