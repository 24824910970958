import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Forbidden from '../views/Forbidden.vue';
import WaveIteration from '../views/WaveIteration.vue';
import Support from '../views/Support.vue';
import store from '../store';
import { hasAccess } from '../utils';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      notAuth: true,
      hideNavbar: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      auth: true,
      role: 'user',
    },
  },
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: Forbidden,
    meta: {
      auth: true,
      role: 'guest',
    },
  },
  {
    path: '/waveIterations/:waveIterationId',
    name: 'WaveIteration',
    component: WaveIteration,
    meta: {
      auth: true,
      role: 'user',
    },
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    meta: {
      auth: true,
      role: 'user',
    },
  },
];

const router = new VueRouter({
  linkActiveClass: 'text-white',

  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.state.auth.user) {
    await store.dispatch('auth/refreshUser/sendRequest');
  }

  if (to.matched.some((record) => record.meta.auth)) {
    if (!store.state.auth.isAuth) {
      return next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    }

    if (to.matched.some((record) => record.meta.role && !hasAccess(store.state.auth.user.role, record.meta.role))) {
      return next({
        path: '/forbidden',
      });
    }
  } else if (to.matched.some((record) => record.meta.notAuth)) {
    if (store.state.auth.isAuth) {
      return next({ path: '/' });
    }
  }

  return next();
});

export default router;
