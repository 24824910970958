
import { isClientError, isServerError, formatErrorKeys } from "../../services/support";

export default {
  namespaced: true,

  state: {
    alertMessage: {
      show: false,
      title: '',
      body: '',
      footer: '',
      variant: 'danger',
      dismissCountDown: 0,
    },
    currentErrors: {},
    errorsCount: 0,
  },

  getters: {
    alertMessage: (state) => state.alertMessage,
    currentErrors: (state) => state.currentErrors,
    clientErrors: (state) => state.currentErrors.clientErrors,
    serverErrors: (state) => state.currentErrors.serverErrors,
    errorsCount: (state) => state.errorsCount,
  },

  mutations: {
    showAlertMessage(state, data) {
      state.alertMessage.variant = data.variant || 'danger';
      state.alertMessage.dismissCountDown = 5;
      state.alertMessage.show = true;
      state.alertMessage.title = data.title;
      state.alertMessage.body = data.body;
      state.alertMessage.footer = data.footer;
    },

    hideAlertMessage(state) {
      state.alertMessage.dismissCountDown = 0;
      state.alertMessage.show = false;
      state.alertMessage.title = '';
      state.alertMessage.body = '';
      state.alertMessage.footer = '';
    },

    setError(state, { type, err }) {
      state.errorsCount += 1;
      state.currentErrors[type] = {
        ...state.currentErrors[type],
        [state.errorsCount]: { ...err }
      };
    },

    clearError(state, type) {
      state.currentErrors[type] = {};
      if (state.errorsCount > 0) {
        state.errorsCount -= 1;
      }
    },

    clearAllErrors(state) {
      state.currentErrors = {};
      state.errorsCount = 0;
    },
  },

  actions: {
    displayNavAlertError({ commit }, { err, alertMessage }) {
      const { title, body: alertBody, footer: foot } = alertMessage;
      const body = alertBody || 'A generic error occured.';
      const footer = foot || 'You can report this issue by clicking ';
      const formatError = formatErrorKeys(err, alertMessage);

      const serverErrorTitle = 'Server Error';
      const clientErrorTitle = 'Client Error';
      const genericErrorTitle = 'Generic Error';

      // If no error, display generic alertMessage error
      if (!err) {
        commit('showAlertMessage', { title, body, footer });
        commit('setError', { type: 'clientErrors', err: formatError });
        return;
      }

      // If error contains 50x statuscode, display generic server error.
      if (isServerError(err)) {
        commit('showAlertMessage', { title: serverErrorTitle, body, footer });
        commit('setError', { type: 'serverErrors', err: formatError });
        return;
      }

      // If error contains 40x statuscode, display client error.
      if (isClientError(err)) {
        commit('showAlertMessage', { title: clientErrorTitle, body, footer });
        commit('setError', { type: 'clientErrors', err: formatError });
        return;
      }
      commit('showAlertMessage', { title: genericErrorTitle, body, footer });
      commit('setError', { type: 'clientErrors', err: formatError });
    },

    displayNavAlertSuccess({ commit }) {
      commit('showAlertMessage', { title: 'Request Sent', body: 'Support request was successfully sent', variant: 'success' });
      commit('clearAllErrors');
    },

    hideNavAlert({ commit }) {
      commit('hideAlertMessage');
    },

    clearError({ commit }, type) {
      commit('clearError', type);
    },
    clearAllErrors({ commit }) {
      commit('clearAllErrors');
    },
  },
};