<template>
  <div class="heatmap">
    <b-container>
      <b-row v-if="columnHeaders">
        <div v-if="rowHeaders" style="flex: 1.5 0" class="d-flex align-items-center">{{ tableLabel }}</div>
        <div v-for="header in columnHeaders" :key="header" style="flex: 1 0">
          <b-col class="heatmap-header text-center p-1">
            {{ header }}
          </b-col>
        </div>
      </b-row>
      <div v-for="(row, rowIdx) in data" :key="`row-${rowIdx}`">
        <b-row>
          <div v-if="rowHeaders" class="d-flex align-items-center" style="flex: 1.5 0; font-weight: 600">
            {{ rowHeaders[rowIdx] }}
          </div>
          <div
            v-for="(cell, cellIdx) in row"
            :key="`cell-${cellIdx}`"
            style="flex: 1 0"
            :id="`${customNameForID}-${rowIdx}-${cellIdx}`"
          >
            <HeatCell
              @click.native="onCellClick(rowIdx, cellIdx)"
              :cellID="`${customNameForID}-${rowIdx}-${cellIdx}`"
              :value="cell"
              :highestValue="highestValueByRow[rowIdx]"
              :color="cellColor || defaultCellColor"
            />
          </div>
        </b-row>
      </div>
      <b-row class="total-container" v-if="displayTotals">
        <div
          v-for="(col, colIdx) in data[0]"
          :key="`col-total-${colIdx}`"
          style="flex: 1 0"
          :id="`${customNameForID}-${col}-${colIdx}`"
        >
          <HeatCell :cellID="`${customNameForID}-${col}-${colIdx}`" class="total-item" :value="columnTotal(colIdx)" />
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import _ from 'lodash';
import HeatCell from './HeatCell.vue';

export default {
  name: 'HeatMap',
  components: {
    HeatCell,
  },
  props: {
    data: Array,
    tableLabel: String,
    columnHeaders: Array,
    rowHeaders: Array,
    displayTotals: Boolean,
    cellColor: String,
    customNameForID: String,
  },
  computed: {
    highestValueByRow() {
      return _.map(this.data, (row) => _.maxBy(row, 'count').count);
    },
  },
  data() {
    return {
      defaultCellColor: '#413DC8',
    };
  },
  methods: {
    onCellClick(row, column) {
      this.$emit('cellClick', { row, column });
    },
    columnTotal(columnIdx) {
      return _.sum(_.unzip(this.data)[columnIdx]);
    },
  },
};
</script>

<style scoped>
.total-container .total-item {
  border-top: 2px solid #343a40;
}

.heatmap-header {
  font-weight: bold;
}
</style>
